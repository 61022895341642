import React, { Component } from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
// import './CommentsClaims.css';
//import { Container} from 'react-bootstrap';
//import constant from '../../../constants.json';
import moment from "moment-timezone";
import _ from "lodash";
import './agGrid.css'

/**
* @class PackageHistory
* @author Matt Anders / Geo Martinez
* @version 1.0
* @param packageid
* @returns claims comments based on claimskey
*/

class PackageHistory extends Component {
state = {
  packageid: this.props.packageid, // this is also the claimskey
  package:this.props.package,
  columnDefs: [
    {
      headerName: "Package Transaction History",
      children: [
        // {headerName: "claimskey", field: "claimskey", sortable:true, filter:true, hide:true},
        {
          headerName: "User ID",
          field: "addedby",
          headerTooltip: "Added By",
          tooltipField: "addedby",
          resizable: true,
          sortable: true,
          filter: true,
          width: 100,
        },
        {
          headerName: "Date",
          field: "addeddate",
          headerTooltip: "Added Date",
          tooltipField: "addeddate",
          resizable: true,
          sortable: true,
          filter: true,
          width: 95,
          cellRenderer: (params) => {
            return moment(params.value).format("MM/DD/YYYY");
          },
          filterParams: {
            valueFormatter: (params) => {
              return moment(params.value).format("MM/DD/YYYY");
            },
        },
        },
        // cellRenderer=(params)=>{if(params.data.lastcscoid) {return (<button name={params.value} onClick={()=>this.lastcscoidHandler(params.data)} title={params.value} style={{background:'none', border:'none',color:'DarkOrange',padding:'0px', cursor:'pointer', textDecoration:'underline'}}>{params.value}</button>)} else {return params.value}}
        {
          headerName: "Action",
          field: "action",
          headerTooltip: "Action",
          tooltipField: "action",
          resizable: true,
          sortable: true,
          filter: true,
          width: 125,
          autoHeight: true, 
          cellClass: "cell-wrap-text"
        },
        {
          headerName: "NXUID",
          field: "nxuid",
          headerTooltip: "Nuxeo document id",
          tooltipField: "nxuid",
          resizable: true,
          sortable: true,
          filter: true,
          width: 70,
          hide: true,
        },
        {
          headerName: "?",
          field: "",
          headerTooltip: "View/Download Claim Image",
          tooltipField: "View/Download Claim Image",
      //    tooltipField: "View/Download supporting Nuxeo documents",
          resizable: true,
          sortable: true,
          filter: true,
          width: 40,
          cellRenderer:(params)=>{
            if(params.data.nxuid) {return (<><a href="_blank"
               title='View Claim image' 
            onClick={(ev)=>{ev.preventDefault();this.linkFormatter(params.data.nxuid)}} style={{textDecoration:'none'}}>&#x1F50D;</a>&emsp;</>) } 
             else {return null}}
          // cellRenderer:(params)=>{
          //   if(params.data.nxuid) {return (<><a href="_blank"
          //      title='View Claim image' 
          //   onClick={(ev)=>{ev.preventDefault();this.linkFormatter(params.data.nxuid)}}>&#x1F50D;</a>&emsp;
          //    <a href="_blank" title='Download Claim Image' onClick={(ev)=>{ev.preventDefault();this.linkDownloader(params.data.nxuid,params.data.addedby)}}>&#x2b07;</a></>) } 
          //    else {return null}}
          // hide: true,1F4C3
          // <img src={process.env.PUBLIC_URL + "/view.png"} height='25px' />
          
        },
        {
          headerName: "Version",
          field: "nxversion",
          headerTooltip: "Nuxeo document version",
          tooltipField: "nxversion",
          resizable: true,
          sortable: true,
          filter: true,
          width: 60,
          hide: true,
        },
        {
          headerName: "Comment",
          field: "comment",
          headerTooltip: "Comment",
          tooltipField: "comment",
          resizable: true,
          sortable: true,
          filter: true,
          width: 280,
          autoHeight: true, 
          cellClass: "cell-wrap-text"
        },
      ],
    },
  ],
  rowData: [], //this.props.commentdata||
  error: false,
  defaultColDef:{ suppressHeaderFilterButton: true, type: 'disableTreeList' },
  columnTypes:{
      disableTreeList: {
        filterParams: { treeList: false },
      },
    }
};
componentDidMount() {
  // console.log(this.state);
  // console.log(this.props);
  // console.log(this.state.packageid)
  //    console.log('commentsClaims did mount')
  // if (this.state.packageid) {
  //   fetch(
  //     localStorage.getItem("apiUrl") +
  //       "/getPackageHistory?packageid=" +
  //       this.state.packageid
  //   )
  //     .then((res) => res.json())
  //     .then((resj) => {
  //       if (resj.rows && resj.rows.length > 0) {
  //         let Rows = _.sortBy(resj.rows, "addeddate").reverse();
  //         this.setState({ rowData: Rows });
  //       } else {
  //         this.setState({ rowData: [] });
  //       }
  //     })
  //     .catch(console.log);
  // }
  if (this.state.package) {
    let rows=_.sortBy(this.state.package.package.history, 'addeddate').reverse()
    let ans = this.wordCap(rows)
    this.setState({rowData: ans})
  } else {
    this.setState({rowData: []})
  }

}

linkDownloader = (nxuid,addedby) => {
    // console.log(nxuid)
    let claimskey=this.state.packageid
    let url=localStorage.getItem("apiUrl") +  `/getDownloadNxDocFileById?nxuid=${nxuid}&claimskey=${claimskey}&addedby=${addedby}`
    //window.location.href=url
    window.open(url,'claimViewer',
    'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on');

    // let filename='Claim-'+nxuid+'.pdf'
    // let link = document.createElement('a');
    // //let url=localStorage.getItem("apiUrl") +  `/getNxDocFileById?nxuid=${nxuid}`
    // //link.href = url
    // //link.download = true //'Claim-'+nxuid+'.pdf'

    // link.setAttribute('href', url);
    // link.setAttribute('download', filename)
    // link.style.display = 'none'

    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)

}

linkFormatter = (nxuid) => {
  let url=localStorage.getItem("apiUrl") +  `/getNxDocFileById?nxuid=${nxuid}`
  this.props.getPDFBlob(url)
  // let winWidth = window.screen.width;
  // let winHeight = window.screen.height - 500;
  // let windowoption=`resizable=yes,height=500,width=850,location=0,menubar=0,scrollbars=1,left=${winWidth},top=${winHeight}`;
  // window.open(url, 'claimViewer',windowoption);
}

wordCap = (rows) => {
  let ans=[]
  // console.log(rows)
  rows.forEach
    (row => {switch(row.action){
      case 'rejected':
        row.action = 'Rejected'
        ans.push(row)
        break
      case 'accepted':
        row.action = 'Accepted'
        ans.push(row)
        break
      case 'pended':
        row.action = 'Pended'
        ans.push(row)
        break
      case 'opened':
        row.action = 'Opened'
        ans.push(row)
        break
      default:
        ans.push(row)
    }})
    return ans
}

componentDidUpdate(prevProps, prevState, snapshot) {
  //    console.log('CommentsClaims did update. StateClaimskey:::PropsClaimsKey ' + this.state.claimskey +' : '+ this.props.claimskey )
  // console.log(this.props);
  // if (
  //   this.props.packageid &&
  //   (prevProps.packageid !== this.props.packageid ||
  //     prevProps.phVersion !== this.props.phVersion)
  // ) {
  //   console.log("cc Fetch");
  //   fetch(
  //     localStorage.getItem("apiUrl") +
  //       "/getPackageHistory?packageid=" +
  //       this.props.packageid
  //   )
  //     .then((res) => res.json())
  //     .then((resj) => {
  //       console.log("cc Got Data");
  //       console.log(resj.rows.length);
  //       if (resj.rows && resj.rows.length > 0) {
  //         let Rows = _.sortBy(resj.rows, "addeddate").reverse();
  //         this.setState({ rowData: Rows });
  //       } else {
  //         this.setState({ rowData: [] });
  //       }
  //     })
  //     .catch(console.log);
  // }
  if (this.props.package && (prevProps.package !== this.props.package || prevProps.phVersion !== this.props.phVersion)) {
//    console.log("cc Fetch");
    let rows=_.sortBy(this.props.package.package.history, 'addeddate').reverse()
    let ans = this.wordCap(rows)
    this.setState({rowData: ans})
  } 
  // else {
  //   this.setState({rowData: []})
  // }
}

changeHandler = (e) => {
  this.setState({ [e.target.name]: parseInt(e.target.value) });
  // console.log(this.state);
};
handleSubmit = (e) => {
  e.preventDefault();
  alert("Claims submitted");
  // console.log(this.state);
};



render() {
  if (!this.state.rowData) {
    return "";
  } else {
    return (
      // <Container fluid className="ag-theme-balham" style={{ width: '100%' }}>
      // </Container>
      (
      <div id="dash-history-grid" style={{ height: "100%", width: "100%" }}>
        <AgGridReact
        onGridReady={(params) => (this.gridApi = params.api)}
      //  rowSelection="single"
        rowSelection={{mode:"singleRow", checkboxes:false, headerCheckbox:false, enableClickSelection:true, "copySelectedRows":false}}
        // onRowSelected = {this.rowSelectedHandler}
        // onSelectionChanged = {this.changeProvider}
        pagination={true}
        paginationAutoPageSize={true}
        autoSize={true}
        columnDefs={this.state.columnDefs}
        rowData={this.state.rowData}
        groupHeaderHeight={20}
        headerHeight={45}
        // rowHeight={20}
        autoHeight={true}
        enableCellTextSelection={true}
      //  suppressCopyRowsToClipboard={true}
        suppressCsvExport={true}
        suppressExcelExport={true}
        defaultColDef={this.state.defaultColDef}
        columnTypes={this.state.columnTypes}

      ></AgGridReact>
      </div>)
    );
  }
}
}

export default PackageHistory;

