import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// ag-grid licensing
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { LicenseManager } from 'ag-grid-enterprise';

//LicenseManager.setLicenseKey('CompanyName=Insight (Orem, UT)_on_behalf_of_Unitedhealth Group Incorporated,LicensedGroup=DMS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-017396,ExpiryDate=19_August_2022_[v2]_MTY2MDg2MzYwMDAwMA==10e3d5bd2d7798613e40f5c0094531aa')
// LicenseManager.setLicenseKey('CompanyName=Insight (Orem, UT)_on_behalf_of_Unitedhealth Group Incorporated,LicensedGroup=DMS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-030442,SupportServicesEnd=19_August_2023_[v2]_MTY5MjM5OTYwMDAwMA==e9b2ce73e7148c25320c0323b608711c')
//LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-042021 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( United Health Group )_is_granted_a_( Multiple Applications )_Developer_License_for_( 10 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 August 2024 )____[v2]_MTcyMzkzNTYwMDAwMA==89abe1b0deb326ee2e3a62cf0febb7a7')

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-064811}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Ricochet_Consulting,_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{10}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_September_2025}____[v3]_[01]_MTc1NzI4NjAwMDAwMA==548ce2b4c822005a0fe886f720747e5b');

//ReactDOM.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//  document.getElementById("root")
//);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
