import React from "react";
import "./UB04.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import { Field } from "formik";
import { textAlign } from "@mui/system";

const TextInput = (props) => {
  const {
    name,
    size,
    prompt,
    maxLength,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
    isBlank,
    validate,
  } = props;

  return (
    <BasicFieldWrapper
      name={name}
      activeField={activeField}
      isBlank={isBlank}
      validate={validate ? validate : null}
    >
      <input
        className="input"
        size={size}
        prompt={prompt}
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        maxLength={maxLength}
        disabled={disabled}
        style={inputStyle ? inputStyle : undefined}
      />
    </BasicFieldWrapper>
  );
};

const DateInput = (props) => {
  const {
    name,
    maxLength,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
    isBlank,
    validate,
  } = props;
  return (
    <BasicFieldWrapper
      name={name} // DATE NAME CHANGED
      activeField={activeField}
      isBlank={isBlank}
      validate={validate ? validate : undefined}
    >
      <input
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        maxLength={maxLength}
        type="text"
        className="input center dateInput"
        disabled={disabled}
        style={inputStyle ? inputStyle : undefined}
      />
    </BasicFieldWrapper>
  );
};

const BasicFieldWrapper = (props) => {
  const {
    name,
    activeField,
    validate,
    description,
    id,
    children,
    wrapperStyles,
    isBlank,
  } = props;
  if (isBlank) {
    return (
      <div className="rowContainer" style={wrapperStyles}>
        {children}
      </div>
    );
  } else {
    return (
      <Field
        name={name}
        validate={validate ? validate : undefined}
        id={id ? id : undefined}
      >
        {({ field, form, meta }) => (
          <ErrorAndDescriptionWrapper
            name={field.name}
            error={meta.error}
            activeField={activeField}
            description={description}
          >
            <div className="rowContainer" style={wrapperStyles}>
              {React.cloneElement(children, { ...field })}
              {meta.error && (
                <ExclamationTriangleFill
                  color={"var(--optum-error-state-red)"}
                  size={12}
                />
              )}
            </div>
          </ErrorAndDescriptionWrapper>
        )}
      </Field>
    );
  }
};

const ErrorAndDescriptionWrapper = (props) => {
  const { error, description, name, activeField, children } = props;
  return (
    <ConditionalWrapper
      condition={error || description?.title}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          show={activeField === name}
          overlay={getPopoverStyling(error, description)}
        >
          {children}
        </OverlayTrigger>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children, descriptions }) =>
  condition ? wrapper(children, descriptions) : children;

const getPopoverStyling = (error, description) => {
  if (error)
    return (
      <Popover className="popoverError">
        <Popover.Content>{error}</Popover.Content>
      </Popover>
    );
  else if (description?.title)
    return (
      <Popover>
        <Popover.Title as="h3">{description.title}</Popover.Title>
        <Popover.Content>{description.long}</Popover.Content>
      </Popover>
    );
};

const validateRevCd = (value) => {
  let error;
  if (value?.length < 1 || !value) {
    error = "Required";
  }

  return error;
};

const validateHcpcs = (value) => {
  let error;
  if (value?.length < 1 || !value) {
    error = "Required";
  }

  return error;
};

const validateServiceDate = (value) => {
  let error;
  if (value?.length < 1 || !value) {
    error = "Required";
  }

  return error;
};

const validateUnits = (value) => {
  let error;
  let val2 = value ? value.toString() : "";
  if (val2.length < 1 || !val2) {
    error = "Required";
  }

  if (val2.includes(".")) {
    let arr = val2.split(".");
    if (arr[0].length > 8) {
      error = "Maximum length to the left of decimal is 8";
    }
    if (arr[1].length > 3) {
      error = "Maximum length to the right of the decimal is 3";
    }
  } else {
    if (val2.length > 8) {
      error = "Maximum length is 8";
    }
  }

  return error;
};

const validateCharge = (value) => {
  let error;
  if (value?.length < 1 || !value) {
    error = "Required";
  }

  return error;
};

const InvoiceLine = (props) => {
  const {
    rowNo,
    handleFocus,
    setFieldTouched,
    activeField,
    isBlank,
    currentPage,
  } = props;
  const space =
    (currentPage == 1 ? rowNo : rowNo - (currentPage - 1) * 22) < 10 ? (
      <span>&thinsp;</span>
    ) : (
      ""
    );
  let ID;
  if (rowNo % 2 == 0) {
    ID = "ub04rowEven";
  } else {
    ID = "ub04rowOdd";
  }

  return (
    <tr id={ID}>
      <td
        colSpan={3}
        style={{ border: "none", background: "none" }}
        className="boldSmallLRightAlighned"
      >
        {currentPage == 1 ? rowNo : rowNo - (currentPage - 1) * 22}
        {space}
      </td>
      <td colSpan={14}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].revenueCode`}
          number={`revenueCode${rowNo - 1}`}
          title="Revenue Code"
          subtitle="Revenue Code"
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          validate={validateRevCd}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
          isBlank={isBlank}
        />
      </td>
      <td colSpan={76}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].description`}
          number={`description${rowNo - 1}`}
          title="Description"
          subtitle="Description"
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
          isBlank={isBlank}
        />
      </td>
      <td colSpan={45}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].hcpcsRateHippsCode`}
          number={`hcpcsRateHippsCode${rowNo - 1}`}
          title="HCPCS Rate Hipps Code"
          subtitle="HCPCS Rate Hipps Code"
          validate={validateHcpcs}
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
          isBlank={isBlank}
        />
      </td>
      <td colSpan={21}>
        <DateInput
          name={`serviceLines[${rowNo - 1}].serviceDate`}
          maxLength={8}
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          validate={validateServiceDate}
          inputStyle={
            ID === "ub04rowEven"
              ? { backgroundColor: "#e0e0e0", width: "100%" }
              : { width: "100%" }
          }
        ></DateInput>
      </td>
      <td colSpan={24}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].unitCount`}
          number={`unitCount${rowNo}`}
          title={`Unit Count`}
          subtitle="Unit Count"
          validate={validateUnits}
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={22} style={{ borderRight: "1px dotted #00000036" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].chargesDollars`}
          number={`chargesDollars${rowNo}`}
          title={`Total Charges Dollars`}
          subtitle="Total Charges Dollars"
          validate={validateCharge}
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven"
              ? { backgroundColor: "#e0e0e0", textAlign: "right" }
              : { textAlign: "right" }
          }
        />
      </td>
      <td colSpan={7} style={{ borderLeft: "none" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].chargesCents`}
          number={`chargesCents${rowNo}`}
          title={`Total Charges Cents`}
          subtitle="Total Charges Cents"
          validate={validateCharge}
          maxLength={2}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={22} style={{ borderRight: "1px dotted #00000036" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].nonCoveredChargesDollars`}
          number={`nonCoveredChargesDollars${rowNo}`}
          title={`Non Covered Charges Dollars`}
          subtitle="Non Covered Charges Dollars"
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          validate={validateCharge}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven"
              ? { backgroundColor: "#e0e0e0", textAlign: "right" }
              : { textAlign: "right" }
          }
        />
      </td>
      <td colSpan={7} style={{ borderLeft: "none" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].nonCoveredChargesCents`}
          number={`nonCoveredChargesCents${rowNo}`}
          title={`Non Covered Charges Cents`}
          subtitle="Non Covered Charges Cents"
          maxLength={2}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          validate={validateCharge}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={8}>
        <TextInput
          name={`49-${rowNo}`}
          number={`49-${rowNo}`}
          title={`49-${rowNo}`}
          subtitle=""
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td
        colSpan={3}
        style={{ border: "none", background: "none" }}
        className="boldSmallLeftAlighned"
      >
        {space}
        {currentPage == 1 ? rowNo : rowNo - (currentPage - 1) * 22}
      </td>
    </tr>
  );
};

export default InvoiceLine;
