import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { red, blue } from "@mui/material/colors";
import { withRouter } from "react-router";

const FederalAttestation = (props) => {
  const [step, setStep] = React.useState(1);
  const [certValue, setCertValue] = React.useState(false);
  const [certAttest, setCertAttest] = React.useState(false);
  const [attValue, setAttValue] = React.useState(false);
  const [attAttest, setAttAttest] = React.useState(false);
  const [defaultCert, setDefaultCert] = React.useState(true);
  const [defaultAtt, setDefaultAtt] = React.useState(true);

  const handleAcceptCertification = () => {
    setCertValue(true);
    setStep(2);
  };

  const handleAcceptAttestation = () => {
    setAttValue(true);
    setStep(5);
  };

  const putUserResponse = (cert, att) => {
    try {
      let forPosting = {
        uuid: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")).sub
          : "Developer",
        userid: localStorage.getItem("userkey")
          ? localStorage.getItem("userkey")
          : "",
        email: localStorage.getItem("email")
          ? localStorage.getItem("email")
          : "",
        name: localStorage.getItem("displayName")
          ? localStorage.getItem("displayName")
          : "",
        attestationResponse: att,
        certificationResponse: cert,
      };
      fetch(`${localStorage.getItem("apiUrl")}/putTrackUserResponse`, {
        method: "post",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(forPosting),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitCert = async () => {
    // let clients = localStorage.getItem("clients")
    //   ? localStorage.getItem("clients")
    //   : "";

    // let clientArr = clients.split(",");

    // let uhcClient = clientArr.includes("UHC") ? true : false;

    // let redirectUrl = uhcClient
    //   ? process.env.REACT_APP_UHC_PROVIDER_URL
    //   : process.env.REACT_APP_PA_URL;

    if (!certValue) {
      await putUserResponse("disagree", null);
      window.alert(
        "The Provider Claim Review program only accepts complete claims that are correct, accurate and truthful.  Please contact Outreach Operations at 1-866-985-8462 to speak to a representative for further instruction."
      );
      localStorage.clear();
      // window.location.assign(redirectUrl);
      window.location.assign(
        process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login"
      );
      return;
    }

    setStep(4);
    await putUserResponse("agree", null);
  };

  const handleSubmitAtt = async () => {
    // let clients = localStorage.getItem("clients")
    //   ? localStorage.getItem("clients")
    //   : "";

    // let clientArr = clients.split(",");

    // let uhcClient = clientArr.includes("UHC") ? true : false;

    // let redirectUrl = uhcClient
    //   ? process.env.REACT_APP_UHC_PROVIDER_URL
    //   : process.env.REACT_APP_PA_URL;

    if (!attValue) {
      await putUserResponse(null, "disagree");
      window.alert(
        "You elected not to attest to having delegated authority to review and take an action on a claim.  Please contact Outreach Operations at 1-866-985-8462 to speak to a representative for further steps."
      );

      localStorage.clear();
      // window.location.assign(redirectUrl);
      window.location.assign(
        process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login"
      );
      return;
    }

    await putUserResponse(null, "agree");
    props.handler();
  };

  const handleCertCheckbox = () => {
    setDefaultCert(true);
  };

  const handleAttCheckbox = () => {
    setDefaultAtt(true);
  };

  const handleCertAttestCheckbox = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setCertAttest(checked);
      setStep(3);
    } else {
      setCertAttest(checked);
      setAttAttest(false);
      setAttValue(false);
      setStep(2);
    }
  };

  const handleAttAttestCheckbox = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setAttAttest(checked);
      setStep(6);
    } else {
      setAttAttest(checked);
      setStep(5);
    }
  };

  const handleRejectAttestation = () => {
    setAttValue(false);
    setStep(5);
  };

  const handleRejectCertification = () => {
    setCertValue(false);
    setStep(2);
  };

  const handleCancel1 = async () => {
    // let clients = localStorage.getItem("clients")
    //   ? localStorage.getItem("clients")
    //   : "";

    // let clientArr = clients.split(",");

    // let uhcClient = clientArr.includes("UHC") ? true : false;

    // let redirectUrl = uhcClient
    //   ? process.env.REACT_APP_UHC_PROVIDER_URL
    //   : process.env.REACT_APP_PA_URL;

    await putUserResponse("disagree", null);
    localStorage.clear();
    // window.location.assign(redirectUrl);
    window.location.assign(
      process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login"
    );
  };

  const handleCancel2 = () => {
    window.alert(
      `Your request to ${
        certValue ? "agree to" : "decline"
      } the federal certification statement has been cancelled.`
    );
    setCertValue(false);
    setCertAttest(false);
    setAttValue(false);
    setAttAttest(false);
    setStep(1);
  };

  const handleCancel3 = () => {
    window.alert(
      `Your request to ${
        attValue ? "agree to" : "decline"
      } the attestation statement has been cancelled.`
    );
    setAttValue(false);
    setAttAttest(false);
    setStep(4);
  };

  return (
    <Container fluid>
      <Row>
        <Col>&emsp;</Col>
      </Row>
      <Row>
        <Col lg={4}></Col>
        <Col lg={4}>
          <Card
            bg={"light"}
            text={"dark"}
            style={{ width: "600px" }}
            className="mb-2"
          >
            <Card.Header>
              {step < 4 ? "Federal Certification" : "Attestation"}
            </Card.Header>
            <Card.Body>
              {step > 0 && step < 4 ? (
                <Card.Text>
                  To the best of my knowledge, the action taken on the claim is
                  complete, accurate and truthful. [See{" "}
                  <a
                    href="https://www.govinfo.gov/content/pkg/CFR-2007-title42-vol3/pdf/CFR-2007-title42-vol3-sec422-502.pdf"
                    target="_blank"
                  >
                    42 CFR 422.502(l)
                  </a>
                  ]
                </Card.Text>
              ) : null}
              {step > 1 && step < 4 ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCertCheckbox}
                        checked={defaultCert}
                        inputProps={{ readOnly: true }}
                        defaultChecked
                        // color={certValue ? "primary" : "secondary"}
                        sx={{
                          color: certValue ? blue : red,
                          "&.Mui-checked": {
                            color: certValue ? blue : red[600],
                          },
                        }}
                      />
                    }
                    label={certValue ? "I Agree" : "I Disagree"}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCertAttestCheckbox}
                        checked={certAttest}
                        // color={certValue ? "primary" : "secondary"}
                        sx={{
                          color: certValue ? blue : red,
                          "&.Mui-checked": {
                            color: certValue ? blue : red[600],
                          },
                        }}
                      />
                    }
                    label={certValue ? "I So Attest" : "I So Abstain"}
                  ></FormControlLabel>
                </>
              ) : null}
              {step > 3 ? (
                <Card.Text style={{ marginTop: 10 }}>
                  By accessing this application, I attest that I have delegated
                  authority to review, accept or reject the conditions reflected
                  on the adjusted claim.
                </Card.Text>
              ) : null}
              {step > 4 ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleAttCheckbox}
                        checked={defaultAtt}
                        defaultChecked
                        // color={attValue ? "primary" : "secondary"}
                        sx={{
                          color: attValue ? blue : red,
                          "&.Mui-checked": {
                            color: attValue ? blue : red[600],
                          },
                        }}
                      />
                    }
                    label={attValue ? "I Agree" : "I Disagree"}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleAttAttestCheckbox}
                        checked={attAttest}
                        // color={attValue ? "primary" : "secondary"}
                        sx={{
                          color: attValue ? blue : red,
                          "&.Mui-checked": {
                            color: attValue ? blue : red[600],
                          },
                        }}
                      />
                    }
                    label={attValue ? "I So Attest" : "I So Abstain"}
                  ></FormControlLabel>
                </>
              ) : null}
            </Card.Body>
            <Card.Footer
              className="justify-content-center"
              style={{ textAlign: "center" }}
            >
              {step === 1 ? (
                <>
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleAcceptCertification}
                  >
                    {`I Agree`.toUpperCase()}
                  </Button>
                  &emsp;&emsp;
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleRejectCertification}
                  >
                    {`I Disagree`.toUpperCase()}
                  </Button>
                  &emsp;&emsp;
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleCancel1}
                  >
                    {`Cancel`.toUpperCase()}
                  </Button>
                </>
              ) : null}

              {step === 3 ? (
                <>
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleSubmitCert}
                  >
                    {`Submit`.toUpperCase()}
                  </Button>
                  &emsp;&emsp;
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleCancel2}
                  >
                    {`Cancel`.toUpperCase()}
                  </Button>
                </>
              ) : null}
              {step === 4 ? (
                <>
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleAcceptAttestation}
                  >
                    {`I Agree`.toUpperCase()}
                  </Button>
                  &emsp;&emsp;
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleRejectAttestation}
                  >
                    {`I Disagree`.toUpperCase()}
                  </Button>
                  &emsp;&emsp;
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleCancel2}
                  >
                    {`CANCEL`.toUpperCase()}
                  </Button>
                </>
              ) : null}

              {step === 6 ? (
                <>
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleSubmitAtt}
                  >
                    {`Submit`.toUpperCase()}
                  </Button>
                  &emsp;&emsp;
                  <Button
                    className="rounded-pill border"
                    size="lg"
                    variant="warning"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#ff612b",
                      color: "white",
                    }}
                    onClick={handleCancel3}
                  >
                    {`Cancel`.toUpperCase()}
                  </Button>
                </>
              ) : null}
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={4}></Col>
      </Row>
      <Row>
        <Col lg={4}></Col>
        <Col className="justify-content-center" lg={4}></Col>
        <Col lg={4}></Col>
      </Row>
    </Container>
  );
};

export default withRouter(FederalAttestation);

// let accept = window.confirm(
//   "Are you sure you want to disagree to the Federal Attestation?"
// );
// if (accept) {
//   // setFedType("submit");
//   // setAttValue(false);
//   let clients = localStorage.getItem("clients")
//     ? localStorage.getItem("clients")
//     : "";
//   let defaultClient = ["UHC"];
//   let clientArr = clients.split(",");
//   let uhcClient = clients
//     ? JSON.stringify(clientArr) === JSON.stringify(defaultClient)
//       ? true
//       : false
//     : true;
//   let redirectUrl = uhcClient
//     ? `https://uhcprovider.com`
//     : process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login";
//   console.log(redirectUrl);
//   window.alert(
//     "It appears you have declined our Federal Attestation. please contact your outreach operations representative at the number located in your package email or 1-866-985-8462"
//   );
//   window.location.assign(redirectUrl);
// } else {
// }

// let accept = window.confirm(
//   "Are you sure you want to disagree to the Federal Certification?"
// );
// if (accept) {
//   // setFedType("attestation");
//   // setCertValue(false);
//   let clients = localStorage.getItem("clients")
//     ? localStorage.getItem("clients")
//     : "";
//   let defaultClient = ["UHC"];
//   let clientArr = clients.split(",");
//   let uhcClient = clients
//     ? JSON.stringify(clientArr) === JSON.stringify(defaultClient)
//       ? true
//       : false
//     : true;
//   let redirectUrl = uhcClient
//     ? `https://uhcprovider.com`
//     : process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login";
//   console.log(redirectUrl);
//   window.alert(
//     "It appears you have declined our Federal Certification. please contact your outreach operations representative at the number located in your package email or 1-866-985-8462"
//   );
//   window.location.assign(redirectUrl);
// } else {
// }
