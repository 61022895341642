import React, { Component } from "react";
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
//import Packages from './Packages';
import PackageProviders from "./PackageProviders";
import PackageResults2 from "./PackageResults2";
import PackageHistory from "./PackageHistory";
import _ from "underscore";
//import ButtonBar from './ButtonBar';
import DashboardBar from "./DashboardBar";
import { withRouter } from "react-router-dom";
import CMSForm from "../components/CMSForm/CMSForm";
import Loading from "../components/CMSForm/Loading";
import PackageProviders2 from "./PackageProviders2";
import NavBarBottom from "./NavBarBottom";
import moment from "moment-timezone";
//import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as htmlToImage from 'html-to-image';
// import {gcmToken} from '../utils/gcm'
// import SinglePage from "../components/pdf/single-page";
import { IconButton, Paper } from "@mui/material";
// import {Window,XCircle} from 'react-bootstrap-icons';
import './Spinners.css'
// import MRMPDFViewer from "../components/pdf/mrmPDFViewer";
// import EOPPdf from "../components/pdf/EOPPdf";
// import MRMPdf from "../components/pdf/MRMPdf";
// import PDFViewer from "../components/pdf/PDFViewer";
import PaymentResults from "./PaymentResults";
// import PaymentHistory from "./PaymentHistory";
import PDFViewer from "../components/pdf/pdfjs-dist/Pdf";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import UB04 from "../components/UB04/UB04.js";
//import { toPng } from 'html-to-image'
//import {Document, Page} from 'react-pdf'

class DashCMS2 extends Component {
  childPR= React.createRef(); // PackageResults2
  isCMSFormValid=false;
  errors={}
  //values={}
  valueIcdCds=[]
  valuePtrs=[]
  state = {
    //providertins:['232837433','463318810','251876056','232176338','481088982','480125463'], //coming from auth piece
    providertins: localStorage.getItem("tin")
    ? localStorage.getItem("tin").split(",")
    : undefined,
  searchCriteria: { tax_id: localStorage.getItem("tin") },
    filter: localStorage.getItem('filter')?localStorage.getItem('filter'):(this.props.location.state && this.props.location.state.filter)?this.props.location.state.filter : null,
     
    prversion: 1,
    showProviders: localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
    showProvidersModal:false,
    showPackages: localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:true,
    showClaim: false,
    twoSided: false,
    cmsMode:'locked', 
    showAutoSign:false, 
    showRejectReason:false,
    autoSigned:false,
    closed:false,
    readOnly:false,
    qOffset:0,
    editenabled:false,
    active:true,
    packageVer:1,
    showLoadingIcon:false,
    showErrorMessage: true,
    showOriginal: false,
    showAdjusted: true,
    disabledAccept: false,
    showNoClaims: false,
    errorVer: false,
    hideVer: 0,
    showVer: 0,
    showHistoryPDF: false,
    showEOP: false,
    showMrmPdf: false,
    showUB04: false,
    zI1: 1,
    zI2: 4,
    zI3: 5,
    zI4: 6,
    zI5: 3,
    zI6: 2,
    arrayTitle: 'Original Claim',
    showArray: false,
    providerSearch: null,
    providerVer: 1,
    foundNxMrm: false, 
    nxUid: null,
    paymentObj: null,
    showPayments: localStorage.getItem('showPayments')&&localStorage.getItem('showPayments')==='true'?true:false,
    mrmSearchIndexs: null,
    foundMrmDos: false,
    uniqueLabels: [],
    currentPageOrg: 1,
    maxPageOrg: 1,
    currentPageAdj: 1,
    maxPageAdj: 1
  };

  changeProvider = (obj) => {
    // console.log(obj);
    this.setState({
      selectedProvider: obj,
      selectedPackage: null,
      searchCriteria: { tax_id: obj.tax_id },
      providerSearch: obj.provider_id,
      filter: 'provider',
      prversion: this.state.prversion + 1,
    });
  };

  changePackage = (pkg) => {
    //c =CMSForm, u = external user id, ck, inv
    //${localStorage.getItem('apiUrl')}/getHit?c=${this.constructor.name}&u=${localStorage.getItem('u')}`
    console.log( pkg)
    let closed=false, readOnly=false
    if (pkg.project.market==='NY') readOnly=true
    if(pkg.package.systemstatus==='accepted'||pkg.package.systemstatus==='rejected') closed=true
    this.setState({ showLoadingIcon: true, showPDFLoadingIcon: false, showHistoryPDF: false, blobdata: null, bloburl: null, mrmUrl: null, zI1: 1, zI2: 4, zI3: 5, zI4: 6, zI5: 3, zI6: 2, arrayTitle: 'Original Claim', showMrmPdf: false, twoSided: false, showArray: false, showEOP: false, foundNxMrm: false, nxUid: null,readOnly:readOnly, showEOP: false});
    
    this.getClaimData(pkg)
    .then(res1 => {
    // console.log(res1)
    this.getDiagsInfo(res1)
    .then(res=>{
      // console.log(res)
      res1.icdAllInfo=[...res]
      // console.log(pkg);
      this.getProcsInfo(res1)
      .then(async res2=>{
        res1.cptsAllInfo=res2
        // console.log(res1)
        // console.log(res1.package.systemstatus)
        // console.log(res1.project.active)
        // res1.serviceLines.push()
         const serviceLineCount = res1.serviceLines ? res1.serviceLines.length : 1;
        if(res1.package.systemstatus==='new'&& res1.project.active===true){ 
          // alert('Please refer to the menu bar, select Resources, and then PCR User Guide for step by step instructions or contact your Outreach Ops representative.')
          // this.printForm()
          let data = {}
          data.claimskey=res1.package.id
          data.addedby=localStorage.getItem('userkey')
          
          let openedClaim = await this.putOpenPackage(data)
          openedClaim.icdAllInfo=[...res]
          openedClaim.cptsAllInfo=res2
          this.setState({selectedPackage: openedClaim, showClaim: true, closed:closed, editenabled:false, packageVer:this.state.packageVer +1, showLoadingIcon: false, currentPageAdj: 1, maxPageAdj: Math.ceil(serviceLineCount / 6), currentPageOrg: 1, maxPageOrg: Math.ceil(serviceLineCount / 6)})  //refresh the dashboard bar button count
          this.childPR.current.updClaim2(openedClaim)
          this.getMrrData(openedClaim.chartid)
          this.getHitHandler({c: 'CMSForm', u: localStorage.getItem('userkey'), ck: openedClaim.claimskey, inv: openedClaim.inventorykey})
          this.props.showBroadcastMessage();
        } else {
          // if(_.contains(['accepted','rejected'],pkg.package.systemstatus)) closed=true
          this.setState({ selectedPackage: res1, showClaim: true, closed:closed, editenabled:false, showLoadingIcon: false, currentPageAdj: 1, maxPageAdj: Math.ceil(serviceLineCount / 6), currentPageOrg: 1, maxPageOrg: Math.ceil(serviceLineCount / 6)});
          this.childPR.current.updClaim2(res1)
          this.getMrrData(res1.chartid)
          console.log(res1)
          this.getHitHandler({c: 'CMSForm', u: localStorage.getItem('userkey'), ck: res1.claimskey, inv: res1.inventorykey})
          // this.setState({editenabled:false});
          this.props.showBroadcastMessage();
        }
      })
      .catch(console.log)
    })
    .catch(console.log)
  }).catch(console.log)
  };

  putOpenPackage = async (data) => {
    try {
    let res = await   fetch(`${localStorage.getItem("apiUrl")}/putOpenPackage`, {
      method: "post",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data),
    })
    let resj=await res.json()
    return resj.claim
     } catch(e){
    console.log(e)
    }
}


  getMrrData = async (chartID) => {
    //Verify source key veriy that the first key or the chart id key is equal too package chart id.
    //check to see if there are search labels. Loop through to find distinct labels along with their page numbers, pass the values to pdf viewer.
    //Next grab the uid and use it for the /getNxDocbyId2
    //if invalid or dont find, fetch with mrm using chart id.
    //https://dwdev.optum.com/api/sg/getNxDocFileById2?nxuid=7eae8462-4895-4e60-ae36-4f35a9be5592/files:files/0/file
    // great chart id: dc97112913e13ad7e0535699330a63ed
    // dc97112911543ad7e0535699330a63ed
    // dc971128f5093ad7e0535699330a63ed

    try {
      // let chartID = "dc97112913e13ad7e0535699330a63ed"; 
      let res = await fetch(
        `${localStorage.getItem("apiUrl")}/getNxDocData?chartID=${chartID}`
      );
      // let res = await  fetch(`http://localhost/api/v1.0/getNxDocData?chartID=d076ad45d7942ac0e0535699330a35e6`)
      let resj = await res.json();
      console.log(resj);
      let uniqueLabels = [];
      let nxChartID = null;
      let uid = null;
      let pages = [];
      let output = [];
      let facets = null
      let properties = null;
      let foundCorrectDOS = false
      if (resj.successful) {
        for(let x = 0; x < resj.data.length; x++) {
        let sourceKeys = resj.data[x].properties["med_rec_src:source_keys"];
        if (sourceKeys !== undefined) {
           uid = resj.data[x].uid;
           facets = resj.data[x].facets;
           properties = resj.data[x].properties;
          for (let x = 0; x < sourceKeys.length; x++) {
            if (sourceKeys[x].key === "chartid") {
              nxChartID = sourceKeys[x].value;
            }
          }

          if (facets && facets.includes("HasPageSearchIndex")) {
            let searchTerms = properties["page_search_index:search_terms"];
            // console.log(searchTerms);
            if (searchTerms) {
              output = searchTerms;
              for (let y = 0; y < searchTerms.length; y++) {
                let searchLabel = searchTerms[y].search_term_label;
                uniqueLabels.push(searchLabel);
                for (let z = 0; z < searchTerms[y].pages.length; z++) {
                  let pageNumber = searchTerms[y].pages[z];
                  pages.push(pageNumber);
                }
              }

              pages = pages.filter((c, index) => {
                return pages.indexOf(c) === index;
              });
            }
          }

          uniqueLabels = await this.beautifyLabels(uniqueLabels);
          let dosFormats = await this.getDosFormats(this.state.selectedPackage.dos);
          // console.log('dosFormats = '  ,dosFormats)
          for(let y = 0; y < uniqueLabels.length; y++) {
            console.log('unique label ' + y + ' = ', uniqueLabels[y])
            if(dosFormats.includes(uniqueLabels[y])) {
              foundCorrectDOS = true
            }
          }

          uniqueLabels = uniqueLabels.concat(dosFormats)
          output = output.filter((value, index) => {
            let _value = JSON.stringify(value);
            return (
              index ===
              output.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          });

          console.log(`uniqueLabels ` + x, uniqueLabels)
          console.log(`nxChartID ` + x, nxChartID)
          console.log(`uid ` + x, uid)
          console.log(`pages ` + x, pages)
          console.log(`output ` + x, output)
          console.log(`facets ` + x, facets)
          console.log(`properties ` + x, properties)
          console.log('did we find the dos? ', foundCorrectDOS)
          if(foundCorrectDOS == true) {
            break
          } else {
            if (x + 1 !== resj.data.length) {
              uniqueLabels = [];
              nxChartID = null;
              uid = null;
              pages = [];
              output = [];
              facets = null
              properties = null;
            }
          }

        } else {
            if (x + 1 !== resj.data.length) {
              uniqueLabels = [];
              nxChartID = null;
              uid = null;
              pages = [];
              output = [];
              facets = null
              properties = null;
            }
         }
      } 

          // console.log(pages);
          // console.log(output);
          // console.log(uniqueLabels);
          if (nxChartID === chartID && uid !== undefined) {
            if(foundCorrectDOS) {
              console.log('did we make it here 1')
              this.setState({
                foundNxMrm: true,
                nxUid: uid,
                mrmSearchIndexs: pages,
                mrmLables: output,
                uniqueLabels: uniqueLabels,
              });
            } else {
              console.log('did we make it here 2')
              uniqueLabels = await this.getDosFormats(this.state.selectedPackage.dos);
              // this.setState({
              //   foundNxMrm: true,
              //   nxUid: uid,
              //   mrmSearchIndexs: null,
              //   mrmLables: null,
              //   uniqueLabels: uniqueLabels,
              // });
              this.setState({
                foundNxMrm: false,
                nxUid: null,
                mrmSearchIndexs: null,
                mrmLables: null,
                foundMrmDos: false,
                uniqueLabels: uniqueLabels,
              });
            }
          } else {
            console.log('did we make it here 3')
            this.setState({
              foundNxMrm: false,
              nxUid: null,
              mrmSearchIndexs: null,
              mrmLables: null,
              foundMrmDos: false,
              uniqueLabels: uniqueLabels,
            });
          }
      } else {
        console.log('did we make it here 4')
        uniqueLabels = await this.getDosFormats(this.state.selectedPackage.dos);
        this.setState({
          foundNxMrm: false,
          nxUid: null,
          mrmSearchIndexs: null,
          mrmLables: null,
          uniqueLabels: uniqueLabels,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        foundNxMrm: false,
        nxUid: null,
        mrmLables: null,
        mrmSearchIndexs: null,
        uniqueLabels: [],
      });
    }
  };

  getClaimData = async (data) => {
    try {
    // let res = await  fetch('https://dwstg.optum.com/api/sg/getOneClaim?claimskey=258680')
    let res = await  fetch(localStorage.getItem('apiUrl') + '/getOneClaim?claimskey=' + data.package.id)
    let resj=await res.json()
    // console.log(resj.rows[0])
    return resj.rows[0]
     } catch(e){
    console.log(e)
    }
}

  getHitHandler = async (data) => {
    try {
    let res = await  fetch(`${localStorage.getItem('apiUrl')}/getHit?c=${data.c}&u=${data.u}&ck=${data.ck}&inv=${data.inv}&name=${localStorage.getItem('displayName')}`)
    } catch(e){
    console.log(e)
    }
}


  clearFilter = () => {
    this.setState({filter: null})
  }

  showHideClaim = (obj) => {
    if (!_.isEmpty(obj)) {
      this.setState({ showClaim: true, twoSided: obj.twoSided || false });
    }
  };

  showHideAdjusted = () => {
    this.setState({showAdjusted: this.state.showAdjusted ? this.state.showOriginal ? false : true : true})
  }

  showHideOriginal = () => {
    this.setState({showOriginal: this.state.showOriginal ? this.state.showAdjusted ? false : true : true})
  }

  rejectionOptions = [
    "no services rendered on date of service",
    "medical record does not support audit finding",
    "wrong provider",
    "provider retired",
    "provider resigned",
    "provider not participating",
    "provider deceased",
    "wrong facility",
    "wrong place of service",
    "wrong patient",
    "patient deceased",
    "patient unknown",
    "wrong date of service",
    // "other" option is handled separately
  ];

  componentDidUpdate(prevProps, prevState) {
    // console.log("Super updated")

    // if(this.state.selectedPackage && this.state.selectedPackage.package && prevState.selectedPackage && prevState.selectedPackage.package && this.state.selectedPackage.package.id !== prevState.selectedPackage.package.id
    //   || this.state.selectedPackage && this.state.selectedPackage.package && !prevState.selectedPackage)
    // {
    //   // this.APIRead()
    // }
  }


  APIRead=()=>{
    if(this.state.selectedPackage && this.state.selectedPackage.package.id)
    {
      fetch(
        localStorage.getItem("apiUrl") +
          "/getCmsData?packageid=" +
          this.state.selectedPackage.package.id
      )
        .then((res) => res.json())
        .then((resj) => {
          // console.log(resj)
          if(resj.rowCount===1){
            this.setState({formData:resj.rows[0]})
            // console.log(resj.rows[0])
          }
        })
        // .then(this.selectFirst())
        .catch((error) => {
          console.log(error);
        })
    }
  }

  // printForm = () => {
  //   const input = document.getElementById('divToPrint');
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       const imgProps= pdf.getImageProperties(imgData);
  //       var divHeight = document.getElementById('divToPrint').clientHeight;
  //       var divWidth = document.getElementById('divToPrint').clientWidth;
  //       var ratio = divHeight / divWidth;
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = ratio * pdfWidth;
  //       pdf.addImage(imgData, 'PNG', 20, 0, pdfWidth - 40, pdfHeight - 60);
  //       pdf.output('dataurlnewwindow');
  //       pdf.save("cmsResultExample.pdf");
  //     })
  //   ;
  // }

  printForm = () => {
    const input = document.getElementById('adjustedCMSForm');
    // console.log("Input: ", input)
    htmlToImage.toPng(input)
    .then((dataUrl) => {
      var img = new Image();
      img.src = dataUrl;
      const pdf = new jsPDF('p', 'px', 'a4');
      var divHeight = input.clientHeight;
      var divWidth = input.clientWidth;
      var ratio = divHeight / divWidth;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = ratio * pdfWidth;
      pdf.addImage(img, 'PNG', 20, 0, pdfWidth - 40, pdfHeight - 70);
      pdf.save("cmsResultExample.pdf");
    })
    .catch((error) => {
      console.error('htmlToImage conversion failed', error)
    })
    ;
  }

  buttonsHandler = (clickedButton) => {
    //added for metrics
    let forPosting={
      dateTime:new Date(),
      user:localStorage.getItem('userkey'),
      name:localStorage.getItem('displayName'),
      email:localStorage.getItem('email'),
      uuid:localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).sub : 'Developer',
      market:this.state.selectedPackage?this.state.selectedPackage.project.market : null ,
      claimId:this.state.selectedPackage?this.state.selectedPackage.claim_id_new : null ,
      claimKey:this.state.selectedPackage?this.state.selectedPackage.claimskey : null ,
      chartid:this.state.selectedPackage?this.state.selectedPackage.chartid : null ,
      action:clickedButton || 'noNameButtons'
    }
    fetch(`${localStorage.getItem("apiUrl")}/postActionStat`, {
      method: "post",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(forPosting),
    }).catch(console.log)

    // console.log(clickedButton)
    // console.log("buttonsHandler");
    switch (clickedButton) {
      case "showProviders":
        // localStorage.setItem('showingProviders',!this.state.showProviders)
        this.setState({ showProviders: !this.state.showProviders });
        break;
      case "showProvidersModal":
        this.setState({ showProvidersModal: true });
        break;
      case "packagesDisplay":
        // console.log(localStorage.getItem('showPackages'))
        localStorage.setItem('showPackages',!this.state.showPackages)
        this.setState({ showPackages: !this.state.showPackages });
        break;
      case "providersDisplay":
        localStorage.setItem('showProviders',!this.state.showProviders)
        this.setState({ showProviders: !this.state.showProviders });
        break;
      case "activeInactive":
        localStorage.setItem('active',!this.state.active)
        this.setState({ active: !this.state.active, filter: 'all', providerSearch: null, searchCriteria: { tax_id: localStorage.getItem("tin") }, });
        break;
      case "paymentsDisplay":
        localStorage.setItem('showPayments',!this.state.showPayments)
        this.setState({ showPayments: !this.state.showPayments });
        break;
      default:
        localStorage.setItem('filter',clickedButton)
        localStorage.setItem('qIndex',JSON.stringify(0))
        // console.log('hey you clicked', clickedButton)
        this.setState({
          filter: clickedButton,
          qOffset:0,
          // packageVer: this.state.packageVer + 1
            // prversion: this.state.prversion + 1,
        });
        break;
    }
  };

  // mrViewer=()=>{
  //   // window.open(`${process.env.PUBLIC_URL}/MedicalRecord-Medium.pdf`,'mrviewer', 'left=100,top=200,height=540,width=960,location=no,scrollbars=on')
  //   // new incorporate GCM chart linkage
  //   // https://medicalrecordmanagerstage.optum.com/gcm-image-viewer/pdfviewer?documentId=%3Cbarcode%3E
    
  //   // non-proxied
  //   // window.open(`${process.env.REACT_APP_GCM_URL || 'https://medicalrecordmanagerstage.optum.com/gcm-image-viewer/pdfviewer'}?documentId=${this.state.selectedPackage.chartid}`,'mrviewer', 'left=100,top=200,height=540,width=960,location=no,scrollbars=on')

  //   // mrWindow.document.title='Medical Record Viewer'
  //   //   mrWindow.document.getElementsByTagName('html')[0]
  //   //  .appendChild(document.createElement('head'))
  //   //  .appendChild(document.createElement('title'))
  //   //  .appendChild(document.createTextNode('Medical Record Viewer'));
  //   // mrWindow.document.write('<title>Medical Record Viewer</title>')
  //   // proxied access
  //   let winName='mrViewer';
  //   let winURL=process.env.REACT_APP_GCM_PROXY;
  //   let winWidth = window.screen.width - 850;
  //   let winHeight = window.screen.height - 500;
  //   let windowoption=`resizable=yes,height=500,width=850,location=0,menubar=0,scrollbars=1,right=${winWidth},top=${winHeight}`;
  //   let params = {authorization: gcmToken(this.state.selectedPackage.chartid, localStorage.getItem('u')),app_name:'jade'};   //valid chart id in non-prod: 'd4d91db6082e3b42e05326dfc90ac60c'
  //   console.log('chart barcode of ' + this.state.selectedPackage.chartid) 
  //   console.log(params) 
  //   console.log(params.authorization)    
  //   let form = document.createElement("form");
  //   form.setAttribute("method", "post");
  //   form.setAttribute("action", winURL);
  //   form.setAttribute("target",winName);  
  //   for (var i in params) {
  //     if (params.hasOwnProperty(i)) {
  //       let input = document.createElement('input');
  //       input.type = 'hidden';
  //       input.name = i;
  //       input.value = params[i];
  //       form.appendChild(input);
  //     }
  //   }              
  //   document.body.appendChild(form);   
  //   // document.title='Medical Record Viewer'                    
  //   window.open('', winName,windowoption);
  //   form.target = winName;
  //   form.submit();                 
  //   document.body.removeChild(form);           
  // }


  // mrViewer=async()=>{
  //  await this.setState({showPDF2: false})
  //  await this.setState({showPDF2: true})
  //   if(this.state.twoSided) {
  //       this.setState({ showProviders: false, showPackages: false, hideVer: this.state.hideVer + 1});
  //   }
  // }

  mrViewer=async()=> {
    // if(this.state.foundMrmDos === false) {
    //   alert('Matching Date of Service - Not Found')
    //  }
    await this.setState({showMrmPdf: false})
   await this.setState({showMrmPdf: true})
  }

  closeMrmPDF = () => {
    this.setState({
      showMrmPdf: false,
      showPackages:localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:false,
      showProviders:localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
      showVer: this.state.showVer + 1
    })
    }

  getPDFBlob = async(url) => {
    await this.setState({showHistoryPDF: false})
   await this.setState({bloburl: url, showHistoryPDF: true})
 }

  closePDF = () => {
  this.setState({
    showHistoryPDF: false,
    showPackages:localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:false,
    showProviders:localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
    showVer: this.state.showVer + 1
  })
  }

  closePDF2 = () => {
    this.setState({
      showMrmPdf: false,
      showPackages:localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:false,
      showProviders:localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
      showVer: this.state.showVer + 1
    })
    }

  packageHistoryFrame = () => {
    return (
      <div style={{height: 1210, width: '850px'}}>
      <Paper elevation={6}>
          <PDFViewer url={this.state.bloburl} 
              name='history'
              closePDF={this.closePDF}
              >
          </PDFViewer>
        </Paper>
        </div>
      )
    }

    ub04Frame = (isFormValidUb04) => {
      return (
        <div style={{ width: '850px'}}>
        <Paper elevation={6}>
          <UB04 currentPage={this.state.currentPageAdj} maxPage={this.state.maxPageAdj} isValidHandler={isFormValidUb04}></UB04>
          </Paper>
          </div>
        )
      }

      eopFrame = () => {
        return (
          <div style={{ width: '850px'}}>
          <Paper elevation={6}>
            <UB04></UB04>
            </Paper>
            </div>
          )
        }

      // eopFrame = () => {
      //   return (
      //     <div style={{height: 1200, width: '850px'}}>
      //     <EOPPdf
      //     changeZindex={this.changeZindex} 
      //     url={`https://dwdev.optum.com/api/sg/getNxDocFileById2?nxuid=327c7dc3-811e-4dae-997f-82a63637f8f7`}>
      //     </EOPPdf>
      //     </div>
      //   )
      //   }


      beautifyLabels = (labels) => {
        // console.log(labels)
        let arr = []
        let val = []
        let labelValue = ''

        for(let x = 0; x < labels.length; x++) {
          val = labels[x].includes('=') ? labels[x].split('=') : labels[x].split('-')
          labelValue = val[1]
          labelValue = labelValue.toLowerCase()
          labelValue = labelValue.charAt(0).toUpperCase() + labelValue.slice(1)
          if(val[0].includes('DOS')) {
          arr.push(labelValue)
          }
        }
        return arr
        
      }

      getDosFormats = (e) => {
        let dates = [
        moment(e).format('MMMM Do YYYY'),
        moment(e).format('MMM Do YYYY'),
        moment(e).format("MMMM DD YYYY"),
        moment(e).format('MMM DD YYYY'),
        moment(e).format('MM/DD/YYYY'),
        moment(e).format('YYYY/MM/DD'),
        // moment(e).format('MM-DD-YYYY'),
        moment(e).format('MM-DD-YY'),
        moment(e).format('MM/DD/YY'),
        moment(e).format('YYYY-MM-DD'),
        moment(e).format('MMMM DD, YYYY'),
        moment(e).format('M/DD/YYYY'),
        moment(e).format('M-DD-YYYY'),
        moment(e).format('M/D/YYYY'),
        moment(e).format('M-D-YYYY'),
        moment(e).format('M/D/YY'),
        moment(e).format('M-D-YY'),
        moment(e).format('M/DD/YY'),
        moment(e).format('M-DD-YY'),
        moment(e).format('MMM DD, YYYY'),
        moment(e).format('MMM D, YYYY'),
        ]
        // console.log(dates)
        let uniqueArray = dates.filter(function(item, pos) {
          return dates.indexOf(item) === pos;
      })
        // console.log(uniqueArray)
        return uniqueArray
      }

       medicalRecordFrame = () => {
         let backupUrl = `${localStorage.getItem("apiUrl")}/getMrmPdf?chartID=${this.state.selectedPackage.chartid}&user=${localStorage.getItem('u')}`
         let url = this.state.foundNxMrm ? `${localStorage.getItem("apiUrl")}/streamNxDocFileById?nxuid=${this.state.nxUid}/files:files/0/file` : backupUrl

        return (
          <div style={{height: 1210, width: '850px'}}>
          <Paper elevation={6} >
            <PDFViewer url={url} name='mrm' closePDF={this.closePDF2} backupUrl={backupUrl} pageIndexs={this.state.mrmSearchIndexs} mrmLables={this.state.mrmLables} uniqueLabels={this.state.uniqueLabels} showMrmDosAlert={this.showMrmDosAlert} rotateClickStats={this.rotateClickStats}></PDFViewer>
            </Paper>
            </div>
        )
        }

  rotateClickStats = () => {
    // console.log('hey you clicked rotate')
    let forPosting={
      dateTime:new Date(),
      user:localStorage.getItem('userkey'),
      name:localStorage.getItem('displayName'),
      email:localStorage.getItem('email'),
      uuid: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).sub : 'Developer',
      market:this.state.selectedPackage?this.state.selectedPackage.project.market : null ,
      claimId:this.state.selectedPackage?this.state.selectedPackage.claim_id_new : null ,
      claimKey:this.state.selectedPackage?this.state.selectedPackage.claimskey : null ,
      chartid:this.state.selectedPackage?this.state.selectedPackage.chartid : null ,
      action: 'rotate'
    }
    // console.log(forPosting)
      fetch(`${localStorage.getItem("apiUrl")}/postActionStat`, {
        method: "post",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(forPosting),
      }).catch(console.log)
  }

  actionHandler = (clickedButton) =>{
    //added for metrics
    let forPosting={
      dateTime:new Date(),
      user:localStorage.getItem('userkey'),
      name:localStorage.getItem('displayName'),
      email:localStorage.getItem('email'),
      uuid: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).sub : 'Developer',
      market:this.state.selectedPackage?this.state.selectedPackage.project.market : null ,
      claimId:this.state.selectedPackage?this.state.selectedPackage.claim_id_new : null ,
      claimKey:this.state.selectedPackage?this.state.selectedPackage.claimskey : null ,
      chartid:this.state.selectedPackage?this.state.selectedPackage.chartid : null ,
      action:clickedButton || 'noNameAction'
    }
    // console.log(forPosting)
      fetch(`${localStorage.getItem("apiUrl")}/postActionStat`, {
        method: "post",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(forPosting),
      }).catch(console.log)

    // let status = this.state.selectedPackage.package.systemstatus

    // console.log(status)

    switch (clickedButton) {
      case 'edit':
        this.setState({editenabled:true})
        // console.log("in edit")
        break
      case 'compare':
        this.setState({twoSided:!this.state.twoSided})
        if(!this.state.twoSided === true && this.state.showHistoryPDF === true) {
            this.setState({ showProviders: false, showPackages: false, hideVer: this.state.hideVer + 1});
        } else {
            this.setState({showPackages:localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:false,
            showProviders:localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
            showVer: this.state.showVer + 1})
        }
        break
      case 'showOriginal':
          this.showHideOriginal()
        break
      case 'showAdjusted':
          this.showHideAdjusted()
        break
      // case 'showOriginal':
      //     this.showHideOriginal()
      //   break
      // case 'showAdjusted':
      //     this.showHideAdjusted()
      //   break
      case "medicalRecord":
        // this.setState({ showMedicalRecord: !this.state.showMedicalRecord });
        // open a new secondary window instead
        // window.open(`${process.env.PUBLIC_URL}/MedicalRecord-Medium.pdf`,'mrviewer', 'height=540,width=960,location=no,scrollbars=on')
        this.mrViewer()
        break
      case "accept":
        // console.log('accept clicked')
        this.validateICDCheck(1)
        break
        // if(this.isCMSFormValid===false) {
        //   this.showError()
        //   break
        // }
        // else {
        //   this.setState({ showAutoSign: !this.state.showAutoSign })
        //   break
        // }
      case "reject":
          // this.setState({ showRejectReason: !this.state.showRejectReason, showErrorMessage : false });
          this.validateICDCheck2('reject')
        break
      case "pend":
          // this.pend()
          this.validateICDCheck2('pend')
          break
          //
          //
      case "array":
        // if(this.state.foundMrmDos === false) {
        //   alert('Matching Date of Service - Not Found')
        //  }
        // if(status.toUpperCase() == 'ACCEPTED' || status.toUpperCase() == 'REJECTED') {
        // this.setState({showArray: true, showMrmPdf: false, twoSided: false, showEOP: true})
        // }  else {
          this.saveCurrentPackageState()
          this.setState({showArray: true, showMrmPdf: false, twoSided: false, arrayTitle: this.state.showUB04 ? 'Original UB04 Form': 'Original Claim'})
        //   this.tempAlert('EOP not available.', 10000)
         
        // }
        break
      case "sidebyside":
        this.saveCurrentPackageState()
        this.setState({twoSided: true, showMrmPdf: false, showArray: false, showEOP: false, showHistoryPDF: false})
        break
      case "tripod":
        // if(this.state.foundMrmDos === false) {
        //   alert('Matching Date of Service - Not Found')
        //  }
        this.saveCurrentPackageState()
        this.setState({showMrmPdf: true, twoSided: true, showArray: false, showEOP: false, showHistoryPDF: false})
        break
      case "fourblock":
        // if(this.state.foundMrmDos === false) {
        //   alert('Matching Date of Service - Not Found')
        //  }
      //  if(status.toUpperCase() == 'ACCEPTED' || status.toUpperCase() == 'REJECTED') {
      //   this.setState({showMrmPdf: true, twoSided: true, showArray: false, showHistoryPDF: false,
      //     showEOP: true,
      //   })
      // } else {
        this.saveCurrentPackageState()
        this.setState({showMrmPdf: true, twoSided: true, showArray: false, showHistoryPDF: false, showEOP: false})
        // this.tempAlert('EOP not available.', 10000)
        // }
        break
        case "single":
          this.saveCurrentPackageState()
          this.setState({showMrmPdf: false, twoSided: false, showArray: false, showEOP: false, showHistoryPDF: false})
          break
        case "eop":
          // this.setState({ showMedicalRecord: !this.state.showMedicalRecord });
          // open a new secondary window instead
          // window.open(`${process.env.PUBLIC_URL}/MedicalRecord-Medium.pdf`,'mrviewer', 'height=540,width=960,location=no,scrollbars=on')
          this.setState({showEOP: true})
          break
      default:
        this.setState({cmsMode:clickedButton})
        break
    }
  }

 validateICDs= async (ICDs,dos)=>{
    // console.log(ICDs)
    try {
        let res = await fetch(localStorage.getItem('apiUrl') + '/getValidateIcdCds?icdType=ICD10&icdCd='+ICDs+'&dos='+dos)
        let resj = await res.json()
        return resj
    } catch(err){
        console.log(err)
    }
}

saveCurrentPackageState = () => {
  let currentIcds = this.valueIcdCds
  let currentPtrs = this.valuePtrs
  let values = this.state.selectedPackage
  
  console.log(currentIcds)
  console.log(currentPtrs)
  console.log(values)

  for(let x = 0; x < values.serviceLines.length; x++) {
    if(values.serviceLines[x].cpt>' ') {
    console.log('org pointer =', values.serviceLines[x].pointer)
    console.log('adjusted pointer =', currentPtrs[x])
    values.serviceLines[x].pointer = currentPtrs[x]
    }
  }

  values.icdCodes = currentIcds
  this.setState({selectedPackage : values})
}

showError2=(errors, type)=>{
  let msg;
    for(const [index, el]of errors.entries()) {
      if(el !== undefined) {
        let Line=index+1
        if(type === 'icd') {
        msg=`Error: ${el}${this.valueIcdCds[index] ? ' ' + this.valueIcdCds[index] : '' } on 21-${this.getDiagsChar(index)}`
        }
         if(type === 'pointer') {
          if(this.valuePtrs[index]) {
            msg='Error: Invalid Pointer '+this.valuePtrs[index]+' on service line '+Line
          }
          else {
            msg='Error: Pointer is required on service line '+Line
          }
         }
        break
    }
  }
  
    alert(msg)
    return

}

showError3=()=>{
  let msg
  if(this.errors.validatingICDs) {
    msg = 'Validating ICD codes. Please allow the operation to complete and try again.'
    return msg
  }
  
  if(this.errors.icdCodes) {
    for(const [index, el]of this.errors.icdCodes.entries()) {
      if(el) {
        msg=`Error: ${el}${this.valueIcdCds[index] ? ' ' + this.valueIcdCds[index] : '' } on 21-${this.getDiagsChar(index)}`
        break
      }
    }
    return msg
  }
  
  if(this.errors.services) {
    for(const [index, el]of this.errors.services.entries()) {
      if(el) {
        // console.log(el)
        // console.log(this.valuePtrs[index])
        let Line=index+1
        if(this.valuePtrs[index]) {
          if(el.charges){
            msg='Error: Invalid Charge on service line '+Line
          } else {
          msg='Error: Invalid Pointer '+this.valuePtrs[index]+' on service line '+Line
          }
        }
        else {
          // msg='Error: Pointer is required on service line '+Line
          continue
        }
        break
      }
    }
    return msg
  }
  // This should never execute:
  msg = 'Error: Please fix errors in the form: Diagnosis Codes or Pointers.'
  return msg
}


  validateICDCheck = async (step) =>{
    //validate all icds on each 'accept' button action. Or possibly just one depending on how we want to do this.
  if(this.isCMSFormValid===false) {
      this.setState({showAutoSign:false, autoSigned:false})
      this.showError()
  } else {
    // console.log(this.valueIcdCds)
    let icdArray = this.valueIcdCds
    icdArray.forEach((element, index) => {
      icdArray[index] = element.replace(/ /g,'')
    });
    let errors = []
    let dosFrom = this.state.selectedPackage.serviceLines[0].dosFrom
    let icdresults = await this.validateICDs(icdArray.join(','),dosFrom)
    let icdrows = icdresults.rows

    // console.log(icdresults)
    // console.log(icdrows)

    for (let i=0; i< icdArray.length; i++) {
        let ICDcode = icdArray[i]
        if(ICDcode !== '') {
          ICDcode = ICDcode.replace(/ /g,'')
            let res = icdrows.find(el => el.diag_cd === ICDcode)
            // console.log(res)
            if (res !== undefined) { 
              if(dosFrom < res.eff_dttm) {
                  errors[i]='Code not yet effective until '+res.eff_dttm.substr(0,10)  
              } else {
                  if(dosFrom > res.exp_dttm) {
                        errors[i]='Code was expired on '+res.exp_dttm.substr(0,10)
                  } else {
                      if(ICDcode === res.orig_diag_cd && ICDcode.includes('.')) {
                          errors[i]='TR3: Do not transmit the decimal point for ICD Codes.'
                      }
                  }
                }
              } else {
                  errors[i]='Invalid Diagnosis Code'
                }     
      
      if(icdArray) {
        for (let i=0; i<11; i++) {
              if (this.replacer(icdArray[i]) === '' && this.replacerV2(i, icdArray) === true ) {
                    errors[i]='Gaps found'
                }
          }
    
          for (let i=0; i<12; i++) {
              if (this.repeatedICDs(icdArray[i], icdArray) > 1 && this.replacer(icdArray[i]) !== '' && this.lastIndex(icdArray[i], icdArray) !== i) {
                    errors[i]='Repeated Diagnosis Code'
                }
          }

        }
      }
    }
    let ptrs = this.valuePtrs
    let ptrErrors = []
    for(let i = 0; i < ptrs.length; i++) {
      let diagPointer = ptrs[i]
      if (diagPointer) {
        let pointerError = this.validateDiagnosisPointer(diagPointer)
        if (pointerError === undefined) {
          let pointerArray = [...diagPointer].map(a => parseInt(a, 36) - 10).filter(a => a >= 0)
          pointerArray.forEach(ptr => {
              if (icdArray[ptr] === undefined || icdArray[ptr].length === 0) {
              if (ptrErrors[i] === undefined)
                  ptrErrors[i] = {};
                if (ptrErrors[i] === undefined)
                ptrErrors[i] = {};
                ptrErrors[i] = "Diagnosis Pointer is Missing or Invalid"
              }
          })
        }
        else {
          if (ptrErrors[i] === undefined)
          ptrErrors[i] = {};
            ptrErrors[i] = pointerError
        }
      } else {
        if (ptrErrors[i] === undefined)
        ptrErrors[i] = {};
        ptrErrors[i] = "Diagnosis Pointer is Missing or Invalid"
      }
    }
    

      // console.log(errors)
      // console.log(ptrErrors)
        if(errors.length > 0) {
          //we found errors in icds
            this.setState({showAutoSign:false, autoSigned:false})
            this.showError2(errors, 'icd')

        } else {
          // we found errors in pointers
            if (ptrErrors.length > 0) {
              this.setState({showAutoSign:false, autoSigned:false})
              this.showError2(ptrErrors, 'pointer')
            } else {

              //we did not find errors, and we do different functions based on intial accept click, autosign click, and final accept click. 
              switch (step) {
                case 1:
                  this.setState({ showAutoSign: !this.state.showAutoSign })
                  break
                case 2:
                  this.setState({autoSigned:true})
                  break
                case 3:
                  this.accept()
                  this.setState({showAutoSign:false, autoSigned:false, packageVer:this.state.packageVer+1})
                  break
                default:
                  break
              }
            }
        }
          
        }
    }

    validateICDCheck2 = async (type) => {
      //validate all icds on each 'accept' button action. Or possibly just one depending on how we want to do this.
      let serviceLines = this.state.selectedPackage.serviceLines;
      let icdArray = this.valueIcdCds;
      let ptrs = this.valuePtrs;
      let ogIcds = this.state.selectedPackage.icdCodes;
      let ogPtrs = []
      for(let x = 0; x < serviceLines.length; x++) {
        let serviceLine = serviceLines[x];
        if(serviceLine.dosFrom) {
          ogPtrs.push(serviceLine.pointer);
        }
      }
      console.log('selected Package => ', this.state.selectedPackage);
      console.log("icdArray =>", icdArray);
      console.log("ogIcds =>", ogIcds);
      console.log("stringified icdArray =>", JSON.stringify(icdArray));
      console.log("stringified ogIcd =>", JSON.stringify(ogIcds));
      console.log("Do the icds match =>",JSON.stringify(icdArray) === JSON.stringify(ogIcds));
      console.log("ptrs =>", ptrs);
      console.log("ogPointers =>", ogPtrs);
      console.log("stringified ptrs =>", JSON.stringify(ptrs));
      console.log("stringified ogPtrs =>", JSON.stringify(ogPtrs));
      console.log("Do the pointers match =>",JSON.stringify(ptrs) === JSON.stringify(ogPtrs));

      if (
        JSON.stringify(icdArray) !== JSON.stringify(ogIcds) ||
        JSON.stringify(ptrs) !== JSON.stringify(ogPtrs)
      ) {

        let error;
        console.log(this.isCMSFormValid);
        console.log(this.errors);
       
        if (this.isCMSFormValid === false) {
          console.log(this.errors);
          error = await this.showError3();
        } 

        if(error) {
          console.log(error)
          alert(error)
          return
        } else {
          // console.log(this.valueIcdCds)
          icdArray.forEach((element, index) => {
            icdArray[index] = element.replace(/ /g, "");
          });
          let errors = [];
          let dosFrom = this.state.selectedPackage.serviceLines[0].dosFrom;
          let icdresults = await this.validateICDs(icdArray.join(","), dosFrom);
          let icdrows = icdresults.rows;
  
          // console.log(icdresults)
          // console.log(icdrows)
  
          for (let i = 0; i < icdArray.length; i++) {
            let ICDcode = icdArray[i];
            if (ICDcode !== "") {
              ICDcode = ICDcode.replace(/ /g, "");
              let res = icdrows.find((el) => el.diag_cd === ICDcode);
              // console.log(res)
              if (res !== undefined) {
                if (dosFrom < res.eff_dttm) {
                  errors[i] =
                    "Code not yet effective until " + res.eff_dttm.substr(0, 10);
                } else {
                  if (dosFrom > res.exp_dttm) {
                    errors[i] =
                      "Code was expired on " + res.exp_dttm.substr(0, 10);
                  } else {
                    if (ICDcode === res.orig_diag_cd && ICDcode.includes(".")) {
                      errors[i] =
                        "TR3: Do not transmit the decimal point for ICD Codes.";
                    }
                  }
                }
              } else {
                errors[i] = "Invalid Diagnosis Code";
              }
  
              if (icdArray) {
                for (let i = 0; i < 11; i++) {
                  if (
                    this.replacer(icdArray[i]) === "" &&
                    this.replacerV2(i, icdArray) === true
                  ) {
                    errors[i] = "Gaps found";
                  }
                }
  
                for (let i = 0; i < 12; i++) {
                  if (
                    this.repeatedICDs(icdArray[i], icdArray) > 1 &&
                    this.replacer(icdArray[i]) !== "" &&
                    this.lastIndex(icdArray[i], icdArray) !== i
                  ) {
                    errors[i] = "Repeated Diagnosis Code";
                  }
                }
              }
            }
          }
          let ptrErrors = [];
          for (let i = 0; i < ptrs.length; i++) {
            let diagPointer = ptrs[i];
            if (diagPointer) {
              let pointerError = this.validateDiagnosisPointer(diagPointer);
              if (pointerError === undefined) {
                let pointerArray = [...diagPointer]
                  .map((a) => parseInt(a, 36) - 10)
                  .filter((a) => a >= 0);
                pointerArray.forEach((ptr) => {
                  if (icdArray[ptr] === undefined || icdArray[ptr].length === 0) {
                    if (ptrErrors[i] === undefined) ptrErrors[i] = {};
                    if (ptrErrors[i] === undefined) ptrErrors[i] = {};
                    ptrErrors[i] = "Diagnosis Pointer is Missing or Invalid";
                  }
                });
              } else {
                if (ptrErrors[i] === undefined) ptrErrors[i] = {};
                ptrErrors[i] = pointerError;
              }
            }
          }
  
          // console.log(errors)
          // console.log(ptrErrors)
          if (errors.length > 0) {
            //we found errors in icds
            this.showError2(errors, "icd");
          } else {
            // we found errors in pointers
            if (ptrErrors.length > 0) {
              this.showError2(ptrErrors, "pointer");
            } else {
              //we did not find errors, and we do different functions based on intial accept click, autosign click, and final accept click.
              switch (type) {
                case "pend":
                  console.log("Made it to the pend with changes");
                  this.pend()
                  break;
                case "reject":
                  console.log("Made it to the reject with changes");
                  this.setState({showRejectReason:true})
                  break;
                default:
                  break;
              }
            }
          }
        }
      } else {
        //the user did not make any changes at all, let them continue with pend or reject
        switch (type) {
          case "pend":
            console.log("Made it to the pend without changes");
            this.pend()
            break;
          case "reject":
            console.log("Made it to the reject without changes");
            this.setState({showRejectReason:true})
            break;
          default:
            break;
        }
      }
    };

    validateDiagnosisPointer = (value) => {
      let error;
      let dupeCheck = this.isIsogram(value)
      if (value?.length > 4) {
        // error = "Only Accept Four Diagnosis Pointers";
        error = 'Diagnosis Pointer is Missing or Invalid'
      }
      if (value?.length > 0 && !/^[A-L]+$/.test(value)) {
        // error = "Only A thru L are valid.";
        error = 'Diagnosis Pointer is Missing or Invalid'
      }
      if (value?.length > 0 && !/^[A-Z]+$/.test(value)) {
        // error = "Does Not Accept Special Characters";
        error = 'Diagnosis Pointer is Missing or Invalid'
      }
      if(value?.length > 0 && dupeCheck === false) {
        // error = 'Diagnosis Pointer Cannot Be Repeated'
          error = 'Diagnosis Pointer is Missing or Invalid'
      }
      return error;
    };

    isIsogram = (str) => {
      return !/(.).*\1/.test(str);
    }


  replacer = (val)=>{
    if ( val === null ) 
    { 
      return "" // change null to empty string
    } else {
      return val // return unchanged
    }
  }

    replacerV2 = (val, arr)=>{
    if(arr) {
    let index = val
    for(let x = index; x < 12; x++) {
      if(arr[x] !== '') {
        return true
      }
    }
  }
    return false
  }

    repeatedICDs = (val, arr)=>{
    // console.log(val)
    // console.log(arr)
    let count = 0;
    arr.forEach(icd => {
      if(icd === val) {
        count += 1
      }
    })
    return count
  }

  lastIndex = (val, arr)=>{
    return arr.indexOf(val)
  }



  tempAlert = (msg,duration) => {
      var el = document.createElement("div");
      el.setAttribute("style","position:fixed;top:30%;left:45%;background-color:white;height:50px;width:300px;border:solid 3px #ff612b;z-index:1000;color:black;text-align:center;");
      el.setAttribute("class", "alert alert-light")
      el.setAttribute("role", "alert")
      el.innerHTML = msg;
      setTimeout(function(){
      el.parentNode.removeChild(el);
      },duration);
      document.body.appendChild(el);
  }

  getDiagsChar = (index) => {
    let abcs = ['A','B','C','D','E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']
    return abcs[index]
  }

  showError=()=>{
    let msg
    if(this.errors.validatingICDs) {
      alert('Validating ICD codes. Please allow the operation to complete and try again.')
      return
    }
    
    if(this.errors.icdCodes) {
      for(const [index, el]of this.errors.icdCodes.entries()) {
        if(el) {
          msg=`Error: ${el}${this.valueIcdCds[index] ? ' ' + this.valueIcdCds[index] : '' } on 21-${this.getDiagsChar(index)}`
          break
        }
      }
      alert(msg)
      return
    }
    
    if(this.errors.services) {
      for(const [index, el]of this.errors.services.entries()) {
        if(el) {
          // console.log(el)
          // console.log(this.valuePtrs[index])
          let Line=index+1
          if(this.valuePtrs[index]) {
            if(el.charges){
              msg='Error: Invalid Charge on service line '+Line
            } else {
            msg='Error: Invalid Pointer '+this.valuePtrs[index]+' on service line '+Line
            }
          }
          else {
            msg='Error: Pointer is required on service line '+Line
          }
          break
        }
      }
      alert(msg)
      return
    }
    // This should never execute:
    alert('Error: Please fix errors in the form: Diagnosis Codes or Pointers.')
    return
  }

  autoSign=()=>{
    // this.setState({autoSigned:true})
    this.validateICDCheck(2)
  }

  finalAccept=()=>{
    // do whatever to finally accept
    this.validateICDCheck(3)
    // this.accept()
    // this.setState({showAutoSign:false, autoSigned:false, packageVer:this.state.packageVer+1})
  }

  showMrmDosAlert = () => {
    alert('Could not auto-match Date of Service. Please review the Medical Record manually to locate the Date of Service.')
    return;
  }

  pend =async()=>{
    let data={}
    data.claimskey=this.state.selectedPackage.package.id
    data.addedby=localStorage.getItem('userkey')
    data.status='pended'
    data.filename=null
    this.setState({ showLoadingIcon: true });
    data.icdCodes=this.valueIcdCds.filter(word => word.length > 0)
    data.pointers=this.valuePtrs

    let claimid = {package: { id: data.claimskey, systemstatus : 'accepted' } }
    console.log(data)
    // console.log(claimid)
    let rejectCheck = await this.verifyUpdate2(data.claimskey)
    // console.log(rejectCheck)
    if(rejectCheck === true) { 
      alert('This claim has already been accepted or rejected.')
      this.setState({  packageVer:this.state.packageVer+1,})//prversion:this.state.prversion+1,
      this.changePackage(claimid)
      return;
    } 

    setTimeout(() => {
      fetch(`${localStorage.getItem("apiUrl")}/putUpdatePackage`, {
        method: "post",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((resj)=>{
          localStorage.setItem('qIndex', (parseInt(localStorage.getItem('qIndex'))+1).toString())
          this.childPR.current.updClaim(resj.claim,1)
          this.setState({ packageVer:this.state.packageVer+1,})//prversion:this.state.prversion+1,
          this.updateDone()
      })
      .catch(async error => {
        if(error) {
          let verifyUpdate = await this.verifyUpdate(data.claimskey, data.status)
          if(verifyUpdate === true) { 
            this.updateDone()
            this.setState({ errorVer:this.state.errorVer+1, qOffset:this.state.qOffset+1, packageVer:this.state.packageVer+1,})//prversion:this.state.prversion+1,
            this.childPR.current.selectNext()
          } else {
          alert('An error occured while processing the request, please try again.')
          this.updateDone()
            this.setState({ errorVer:this.state.errorVer+1, qOffset:this.state.qOffset+1, packageVer:this.state.packageVer+1,})//prversion:this.state.prversion+1,
            this.childPR.current.selectNext()
        }
        }
      })
    }, 2000)
  }

  updateDone = async ()=>{
   await this.setState({showLoadingIcon: false,editenabled:false, showPDFLoadingIcon: false, showHistoryPDF: false, blobdata: null, bloburl: null, mrmUrl: null, zI1: 1, zI2: 4, zI3: 5, zI4: 6, zI5: 3, zI6: 2, arrayTitle: 'Original Claim', showMrmPdf: false, twoSided: false, showArray: false, showEOP: false})
  }

  accept = async()=>{
    let data={}
    console.log(this.state.selectedPackage)

    data.claimskey=this.state.selectedPackage.package.id
    data.addedby=localStorage.getItem('userkey')
    data.icdCodes=this.valueIcdCds.filter(word => word.length > 0)
    data.pointers=this.valuePtrs
//    data.claims=this.state.selectedPackage.claims
    data.status='accepted'
    data.filename=null

    let claimid = {package: { id: data.claimskey, systemstatus : 'accepted'  } }
    console.log(data)
    // console.log(claimid)
    let rejectCheck = await this.verifyUpdate2(data.claimskey)
    // console.log(rejectCheck)
    if(rejectCheck === true) { 
      alert('This claim has already been accepted or rejected.')
      this.setState({  packageVer:this.state.packageVer+1,})//prversion:this.state.prversion+1,
      this.changePackage(claimid)
      return;
    } 

    this.setState({ showLoadingIcon: true });
    setTimeout(() => {
      fetch(`${localStorage.getItem("apiUrl")}/putUpdatePackage`, {
        method: "post",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((resj)=>{
          localStorage.setItem('qIndex', (parseInt(localStorage.getItem('qIndex'))+1).toString())
          this.childPR.current.updClaim(resj.claim,0)
          this.setState({ packageVer:this.state.packageVer+1,})
          this.updateDone()
      })
      .catch(async error => {
     
        if(error) {
          let verifyUpdate = await this.verifyUpdate(data.claimskey, data.status)
          // console.log(verifyUpdate)
          if(verifyUpdate === true) {
            this.updateDone()
            this.setState({packageVer: this.state.packageVer + 1, errorVer: this.state.errorVer + 1})
            this.childPR.current.selectNext()
          } else {
          alert('An error occured while processing your request, please try again.')
          this.updateDone()
          this.setState({packageVer: this.state.packageVer + 1, errorVer: this.state.errorVer + 1})
          this.childPR.current.selectNext()
          }
        }
      })
    }, 2000)
  }

  verifyUpdate = async (id, status) => {
    let res = await  fetch(localStorage.getItem('apiUrl') + '/getOneClaim?claimskey=' + id)
    let resj=await res.json()
    let data = resj.rows[0]
    // console.log(data.package.systemstatus.toUpperCase())
    // console.log(status.toUpperCase())
    if(data.package.systemstatus.toUpperCase() === status.toUpperCase()) {
         return true
    } 

    return false 
}

  verifyUpdate2 = async (id) => {
    let res = await  fetch(localStorage.getItem('apiUrl') + '/getOneClaim?claimskey=' + id)
    let resj=await res.json()
    let data = resj.rows[0]
    // console.log(data.package.systemstatus.toUpperCase())

    if((data.package.systemstatus.toUpperCase() === 'ACCEPTED') || (data.package.systemstatus.toUpperCase() === 'REJECTED')) {
        return true
    } 

    return false 
  }


  
  setRejectionReason=(e)=>{
    // console.log(e.target.name + ' and ' + e.target.value)
    this.setState({rejectionReason:e.target.value})
  }

  setOtherRejectionReason=(e)=>{
    this.setState({otherRejectionReason:e.target.value})
  }

  rejectHandler = async (e) => {
    let data={}
      data.claimskey=this.state.selectedPackage.package.id
      data.addedby=localStorage.getItem('userkey')
      data.status='rejected'
      data.filename=null
      data.icdCodes=this.valueIcdCds.filter(word => word.length > 0)
      data.pointers=this.valuePtrs
      
    let claimid = {package: { id: data.claimskey, systemstatus : 'accepted'  } }
    console.log(data)
    // console.log(claimid)
    let rejectCheck = await this.verifyUpdate2(data.claimskey)
    // console.log(rejectCheck)
    if(rejectCheck === true) { 
      alert('This claim has already been accepted or rejected.')
      this.setState({ showRejectReason:false, rejectionReason:null, otherRejectionReason:null, packageVer:this.state.packageVer+1,showErrorMessage: true})//prversion:this.state.prversion+1,
      this.changePackage(claimid)
      return;
    } 
    if (this.state.rejectionReason) {
      if (this.state.rejectionReason==='OTHER') {
        if(this.state.otherRejectionReason) {
            data.reason=this.state.rejectionReason + ':' + this.state.otherRejectionReason
        }        
        else 
        {
          alert('Please enter a reason for the OTHER rejection.')
          return
        }
      }
      else {
        data.reason=this.state.rejectionReason 
      }
      this.setState({showRejectReason:false, showLoadingIcon: true })
   
      console.log(data)
      setTimeout(() => {
        fetch(`${localStorage.getItem("apiUrl")}/putUpdatePackage`, {
          method: "post",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((resj)=>{
     
          localStorage.setItem('qIndex', (parseInt(localStorage.getItem('qIndex'))+1).toString())
          this.childPR.current.updClaim(resj.claim,0)
          this.setState({showRejectReason:false, rejectionReason:null, otherRejectionReason:null, packageVer:this.state.packageVer+1,showErrorMessage: true})
          this.updateDone()
     
        })
        .catch(async error => {
         
          if(error) {
            let verifyUpdate = await this.verifyUpdate(data.claimskey, data.status)
            // console.log(verifyUpdate)
            if(verifyUpdate === true) {
              this.updateDone()
              this.setState({ errorVer:this.state.errorVer+1,showRejectReason:false, rejectionReason:null, otherRejectionReason:null, packageVer:this.state.packageVer+1,showErrorMessage: true})//prversion:this.state.prversion+1,
              this.childPR.current.selectNext()
            } else {
              // let claimid2 = {package: { id: data.claimskey, systemstatus : ''  } }
              alert('An error occured while processing your request, please try again.')
              this.updateDone()
              this.setState({ errorVer:this.state.errorVer+1,showRejectReason:false, rejectionReason:null, otherRejectionReason:null, packageVer:this.state.packageVer+1,showErrorMessage: true})//prversion:this.state.prversion+1,
              this.childPR.current.selectNext()
            }
          }
        })
      }, 2000)
    } else {
      alert('Please select a rejection reason.')
    }
  }
  
  getDiagsInfo=async (pkg)=>{
    try{
      // console.log(pkg.icdCodes)
      // console.log(pkg.icdStds)
      let codes=[...pkg.icdCodes,...pkg.icdStds]
      let res=await fetch(`${localStorage.getItem("apiUrl")}/getValidateIcdCds?icdType=ICD10&icdCd=${codes.join(',').replace(/ /g,'').toUpperCase()}&dos=${pkg.dos}`)
      let resj=await res.json()
      // console.log(resj)
      return resj.rows
    } catch(e){
      console.log(e)
    }
  }

  changeZindex = (name) => {
    // console.log(e.target.name)
    // zi1 = eop
    // zi4 = org
    // zi3 = adj
    // zi2 = medicalRecord
    // zi5 = history
    // zI6 = eop
    if(this.state.showArray) {
    switch (name) {
      // case "eop":
      //   this.setState({ 
      //     zI1: 6,
      //     zI4: 5,
      //     zI3: 4,
      //     zI2: 3,
      //     zI5: 2,
      //     zI6: 1,
      //     arrayTitle: 'Explanation Of Payment'
      //    });
      //   break;
      case "orgClaim":
        this.setState({ 
          zI1: 1,
          zI4: 6,
          zI3: 5,
          zI2: 4,
          zI5: 3,
          zI6: 2,
          arrayTitle: this.state.showUB04 ? 'Original UBO4 Form' : 'Original Claim'
         });
        break;
      case "adjClaim":
        this.setState({ 
          zI1: 2,
          zI4: 1,
          zI3: 6,
          zI2: 5,
          zI5: 4,
          zI6: 3,
          arrayTitle: this.state.showUB04 ? 'Adjusted UBO4 Form' : 'Adjusted Claim'
         });
        break;
        case "mrm":
          this.setState({ 
            zI1: 3,
            zI4: 2,
            zI3: 1,
            zI2: 6,
            zI5: 5,
            zI6: 4,
            arrayTitle: 'Medical Record'
           });
          break;
        case "history":
          this.setState({ 
            zI1: 4,
            zI4: 3,
            zI3: 2,
            zI2: 1,
            zI5: 6,
            zI6: 5,
            arrayTitle: 'Package History'
           });
          break;
        case "eop":
          this.setState({ 
            zI1: 5,
            zI4: 4,
            zI3: 3,
            zI2: 2,
            zI5: 1,
            zI6: 6,
            arrayTitle: 'Explanation Of Payment'
            });
          break;
      default:
        this.setState({ 
          zI1: 1,
          zI4: 6,
          zI3: 5,
          zI2: 4,
          zI5: 3,
          zI6: 2,
          arrayTitle: this.state.showUB04 ? 'Original UBO4 Form' : 'Original Claim'
         });
        break;
    }
  }
  }

  getProcsInfo=async (pkg)=>{
    try {
      let codes=[...pkg.cpts]
      if(codes.length>0) {
        let res=await fetch(`${localStorage.getItem("apiUrl")}/getValidateProcCds?procCd=${codes.join(',').replace(/ /g,'').toUpperCase()}`)
        let resj=await res.json()
        // console.log(resj)
        return resj.rows
      }
      else {
        return []
      }
    } catch(e){
      console.log(e)
    }
    
  }

  exitEdit=()=>{
    this.setState({editenabled:false})
  } 

  countCheck=(counts)=>{
    this.setState({countCheck : counts.totalPackages})
    // console.log(this.state.countCheck)
  }

  disabledAccept = () => {
    this.setState({disabledAccept: true})
  }

  enableAccept = () => {
    this.setState({disabledAccept: false})
  }

  clearClaims = () => {
    this.setState({showNoClaims : true, selectedPackage: null})
  }

  showClaims = () => {
    this.setState({showNoClaims : false})
  }

  selectPayment = (e) => {
    // console.log(e)
    this.setState({paymentObj: e})
  }

  handleOrgPageChange = (num) => {
    let val = this.state.currentPageOrg + num
    if(val <= this.state.maxPageOrg && val > 0) {
    this.setState({currentPageOrg: val })
    this.setState({currentPageAdj: val})
    }
  }

  handleAdjPageChange = (num) => {
    let val = this.state.currentPageAdj + num
    console.log(val)
    console.log(this.state.maxPageAdj)
    if(val <= this.state.maxPageAdj && val > 0) {
      this.setState({currentPageOrg: val })
    this.setState({currentPageAdj: val})
    }
  }

  getColSizing = (id) => {
    console.log('is this firing bro')
    let size = 6
    switch(id) {
      case 'eop':
        size = this.state.twoSided ? 
                  this.state.showMrmPdf & this.state.showHistoryPDF ?  12 : 
                      this.state.showMrmPdf || this.state.showHistoryPDF ? 6 : 12 
               :        this.state.showMrmPdf & this.state.showHistoryPDF ?  6 : 
               this.state.showMrmPdf || this.state.showHistoryPDF ? 12 : 6
      break;
    case 'packageHistory':
      size = this.state.twoSided ? 
                this.state.showMrmPdf || this.state.showEOP? 6 : 12 
              : this.state.showMrmPdf ? this.state.showEOP ? 6 : 12 : 6

  }
  return size
  }

  render() {
    // console.log(this.state.filter);
    // console.log(this.state.qOffset)
    // console.log(process.env.PUBLIC_URL)
    // console.log(this.state.selectedPackage)

    const isOrigFormValid = (isValid) => {
    }

    const isFormValid = (isValid, errors, values) => {
      // console.log("returning isValid in parent component: ", isValid)
      // console.log("returning errors in parent component: ", errors)
      // console.log("returning values in parent component: ", values)
      //this.setState({isCMSFormValid:isValid})
      this.isCMSFormValid=isValid
      this.errors=errors
      //this.values=values
      this.valueIcdCds=[...values.icdCodes]
      let temp=[]
      let sub=0
      values.services.forEach(row => { if(row.cpt>' ') {
        temp.push(values?.services[sub]?.pointer)
        sub++
        }
      })
      this.valuePtrs=temp
      // console.log(this.valueIcdCds)
      // console.log(this.valuePtrs)
    }

    const isFormValidUb04 = (isValid, errors, values) => {
      this.isUb04FormValid=isValid
      this.errorsUb04=errors
    }

    

    return (
      <Container fluid className="m-0 p-0 ag-theme-balham">
        <Row>
          <Col sm={12}>
            <DashboardBar
              providertins={this.state.providertins}
              handlers={this.buttonsHandler}
              displayHandlers={this.buttonsHandler}
              packageVer={this.state.packageVer}
              countCheck={this.countCheck}
              showVer={this.state.showVer}
              hideVer={this.state.hideVer}
            />
          </Col>
        </Row>
        <div style={{display: this.state.showProviders ? 'block' : 'none'}}>
          <Row className="m-0 p-0 ag-theme-balham" style={{ height: "240px" }}>
            <Col sm={12} className="mt-1 p-0">
              <PackageProviders
                changeHandler={this.changeProvider}
                providertins={this.state.providertins}
                active={this.state.active}
              />
            </Col>
          </Row>
        </div>
        <div style={{display: (this.state.showPackages===true ? 'block' : 'none')}}>
          <Row className="m-0 p-0 ag-theme-balham" style={{ height: "240px" }}>
            <Col sm={8} className="mt-1 p-0">
              <PackageResults2
                ref={this.childPR}
                changeHandler={this.changePackage}
                showHideClaim={this.showHideClaim}
                searchCriteria={this.state.searchCriteria}
                prVersion={this.state.prversion}
                filter={this.state.filter}
                packageid={(this.state.selectedPackage && this.state.selectedPackage.package.id ? this.state.selectedPackage.package.id : '')}
                qOffset={this.state.qOffset}
                active={this.state.active}
                editenabled={this.state.editenabled}
                countCheck={this.state.countCheck}
                exitEdit={this.exitEdit}
                clearFilter={this.clearFilter}
                clearClaims={this.clearClaims}
                showClaims={this.showClaims}
                errorVer={this.state.errorVer}
                packageVer={this.state.packageVer}
                providerSearch={this.state.providerSearch}
                providerVer={this.state.providerVer}
              />
            </Col>
            <Col
              sm={4}
              className="mt-1"
              style={{ paddingLeft: "5px", paddingRight: "0px" }}
            >
              {this.state.selectedPackage ? (
                <PackageHistory
                  packageid={(this.state.selectedPackage && this.state.selectedPackage.package.id ? this.state.selectedPackage.package.id : '')}
                  package={this.state.selectedPackage}
                  phVersion={this.state.phversion}
                  getPDFBlob={this.getPDFBlob}
                />
              ) : null}
            </Col>
          </Row>
        </div>
        {this.state.showPayments===true && JSON.parse(localStorage.getItem('userPayEligible'))
      ?
          <Row className="m-0 p-0 ag-theme-balham" style={{ height: "240px" }}>
            <Col id="dash-payments-grid" sm={12} className="mt-1 p-0">
              <PaymentResults
                packageVer={this.state.packageVer}
                selectPaymentHandler={this.selectPayment}
                providertins={this.state.providertins}
              />
            </Col>
            {/* <Col
              sm={4}
              className="mt-1"
              style={{ paddingLeft: "5px", paddingRight: "0px" }}
            >
              {this.state.selectedPackage ? (
                <PaymentHistory
                  paymentObj={this.state.paymentObj}
                />
              ) : null}
            </Col> */}
          </Row>

          : null}
  
        <Row className="m-0 p-0">
          <Col sm={12} style={{ marginTop: "5px"}}>
       
                {
                this.state.showNoClaims === true ?
                <h5 style={{textAlign:'center', marginTop:'10px'}}>{'The queue is empty'.toUpperCase()}</h5>
                : 
                this.state.selectedPackage?

                this.state.showArray ? 
                <>
                {
                  this.state.showLoadingIcon === true ?
                  <Loading/> : 
                  <div style={{display: 'block'}}>
                  <div className="diagonal-box bg-one">
                    <h5 style={{textAlign:'center', marginTop:'10px'}}>{this.state.arrayTitle.toUpperCase()}</h5>
                        <div className="content">
                             <div className={this.state.showHistoryPDF && this.state.showEOP ? "boxes4" : this.state.showHistoryPDF || this.state.showEOP ? "boxes" :"boxes3"}>
                              {this.state.showUB04 ? 
                                          <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI4, marginTop: this.state.showHistoryPDF && this.state.showEOP ? "25%" : this.state.showHistoryPDF || this.state.showEOP  ? '20%': '15%', marginLeft: '5%', marginBottom: '5%'}}>
                                          <Paper style={{marginTop: -40}} elevation={6} onClick={() => this.changeZindex('orgClaim')}>
                                          {this.state.maxPageOrg > 1 ?
                                            <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, marginTop:50, padding: '15px 15px 0px', textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                                              <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                                                <IconButton title='Page Backward' onClick={() => this.handleOrgPageChange(-1)}>
                                                  <ChevronLeft fontSize='large'></ChevronLeft>
                                                </IconButton>
                                                  {this.state.currentPageOrg + ' of ' + this.state.maxPageOrg}
                                                <IconButton  title='Page Forward' onClick={() => this.handleOrgPageChange(1)} >
                                                  <ChevronRight fontSize='large'></ChevronRight>
                                                </IconButton>
                                              </div>
                                             </div>
                                             : null
                                             }
                                               <div style={{ width: '850px'}}>
                                                <Paper elevation={6}>
                                                  <UB04 currentPage={this.state.currentPageAdj} maxPage={this.state.maxPageAdj} isValidHandler={isFormValidUb04}></UB04>
                                                </Paper>
                                              </div>
      
                                        </Paper>
                                      </div>  
                                      :
                                <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI4, marginTop: this.state.showHistoryPDF && this.state.showEOP ? "25%" : this.state.showHistoryPDF || this.state.showEOP  ? '20%': '15%', marginLeft: '5%', marginBottom: '5%'}}>
                                    <Paper style={{marginTop: -40}} elevation={6} onClick={() => this.changeZindex('orgClaim')}>
                                    {this.state.maxPageOrg > 1 ?
                                      <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, marginTop:50, padding: '15px 15px 0px', textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                                        <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                                          <IconButton title='Page Backward' onClick={() => this.handleOrgPageChange(-1)}>
                                            <ChevronLeft fontSize='large'></ChevronLeft>
                                          </IconButton>
                                            {this.state.currentPageOrg + ' of ' + this.state.maxPageOrg}
                                          <IconButton  title='Page Forward' onClick={() => this.handleOrgPageChange(1)} >
                                            <ChevronRight fontSize='large'></ChevronRight>
                                          </IconButton>
                                        </div>
                                       </div>
                                       : null
                                       }
                                   <CMSForm adjustedClaim={this.state.selectedPackage} currentPage={this.state.currentPageOrg} original={true} disabled={'all'} mode='locked' isValidHandler={isOrigFormValid}/>

                                  </Paper>
                                </div>  
                                }
                                {this.state.showUB04 ?
                                    <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI3, marginLeft: this.state.showHistoryPDF && this.state.showEOP ? "-65%" : this.state.showHistoryPDF || this.state.showEOP ? '-60%' : '-40%',  marginTop:this.state.showHistoryPDF && this.state.showEOP ? "20%" : this.state.showHistoryPDF || this.state.showEOP  ? '15%': '10%'}}>
                                    <Paper style={{marginTop: -40}} elevation={6} onClick={() => this.changeZindex('adjClaim')}>
                                    {this.state.maxPageAdj > 1 ?
                                    <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, padding: '15px 15px 0px', marginTop:50, textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                                      <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                                        <IconButton title='Page Backward' onClick={() => this.handleAdjPageChange(-1)}>
                                          <ChevronLeft fontSize='large'></ChevronLeft>
                                        </IconButton>
                                        {this.state.currentPageAdj + ' of ' + this.state.maxPageAdj}
                                        <IconButton title='Page Forward' onClick={() => this.handleAdjPageChange(1)}>
                                          <ChevronRight fontSize='large'></ChevronRight>
                                        </IconButton>
                                      </div>
                                    </div>
                                    : null 
                                    }
                                    <div style={{ width: '850px'}}>
                                      <Paper elevation={6}>
                                        <UB04 currentPage={this.state.currentPageAdj} maxPage={this.state.maxPageAdj} isValidHandler={isFormValidUb04}></UB04>
                                      </Paper>
                                    </div>
          
                                  </Paper>
                                </div>
                                  :
                                <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI3, marginLeft: this.state.showHistoryPDF && this.state.showEOP ? "-65%" : this.state.showHistoryPDF || this.state.showEOP ? '-60%' : '-40%',  marginTop:this.state.showHistoryPDF && this.state.showEOP ? "20%" : this.state.showHistoryPDF || this.state.showEOP  ? '15%': '10%'}}>
                                    <Paper style={{marginTop: -40}} elevation={6} onClick={() => this.changeZindex('adjClaim')}>
                                    {this.state.maxPageAdj > 1 ?
                                    <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, padding: '15px 15px 0px', marginTop:50, textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                                      <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                                        <IconButton title='Page Backward' onClick={() => this.handleAdjPageChange(-1)}>
                                          <ChevronLeft fontSize='large'></ChevronLeft>
                                        </IconButton>
                                        {this.state.currentPageAdj + ' of ' + this.state.maxPageAdj}
                                        <IconButton title='Page Forward' onClick={() => this.handleAdjPageChange(1)}>
                                          <ChevronRight fontSize='large'></ChevronRight>
                                        </IconButton>
                                      </div>
                                    </div>
                                    : null 
                                    }
                                    <CMSForm adjustedClaim={this.state.selectedPackage} currentPage={this.state.currentPageAdj} original={false} disabled={'partial'} editenabled = {this.state.editenabled} mode={this.state.cmsMode} isValidHandler={isFormValid} showErrorMessage={this.state.showErrorMessage} disabledAccept={this.disabledAccept} enableAccept={this.enableAccept} disabledAcceptButton={this.state.disabledAccept}/>
         
                                  </Paper>
                                </div>
                                }
                                <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI2, marginLeft: this.state.showHistoryPDF && this.state.showEOP ? "-138%" : this.state.showHistoryPDF || this.state.showEOP  ? '-125%' : '-90%',  marginTop: this.state.showHistoryPDF && this.state.showEOP ? "10%" : this.state.showHistoryPDF || this.state.showEOP  ? '5%': 0}} onClick={() => this.changeZindex('mrm')} name='mrm'>
                                  {this.medicalRecordFrame()}
                              </div>
                              {this.state.showHistoryPDF ? 
                              <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI5, marginLeft: this.state.showEOP ? '-210%' : '-185%', marginTop: this.state.showEOP ? '5%': 0}} onClick={() => this.changeZindex('history')} name='history'>
                              {this.packageHistoryFrame()} 
                             </div>
                             : null
                              }
                             {this.state.showEOP ? 
                              <div style={{  height: '1150px', width: '850px',zIndex: this.state.zI6, marginLeft: this.state.showHistoryPDF ? '-285%': '-185%'}} onClick={() => this.changeZindex('eop')} name='ub04'>
                              {this.eopFrame()} 
                             </div>
                             : null
                              }
                          </div>
                            {/* } */}
                        </div>
                    </div>
                    </div>
              }
               </>
                :
                <Container fluid="xs" className="m-0 p-0" style={{width:'100%'}}>
                  <Row >
                  {
                    this.state.showLoadingIcon === true ?
                    <Loading/> : 
                    <>
                      {this.state.showEOP? 
                      < Col sm={this.getColSizing('eop')}>
                       <center>
                      <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{'Explanation Of Payment'.toUpperCase()}</h5>
                      {this.eopFrame()}
                      </center>
                    </Col> 
                    : null}

                    {this.state.showHistoryPDF? 
                    <Col sm={this.getColSizing('packageHistory')}>
                       <center>
                      <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{'Package History'.toUpperCase()}</h5>
                      {this.packageHistoryFrame()}
                      </center>
                    </Col> 
                    : null}
                      {this.state.showMrmPdf? 
                   <Col sm={this.state.twoSided ? this.state.showEOP || this.state.showHistoryPDF? 6 : 12: 6}>
                     <center>
                      <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{'Medical Record'.toUpperCase()}</h5>
                      {this.medicalRecordFrame()}
                      </center>
                    </Col> 
                    : null}
                   {this.state.twoSided? 
                   this.state.showUB04 ? 

                    <Col sm={6}>
                      <div style={{display:'table', margin: 'auto'}}>
                        <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{'Original UB04 Form'.toUpperCase()}</h5>
                        <Paper style={{ width: '850px'}} elevation={6} onClick={() => this.changeZindex('orgClaim')}>
                          {this.state.maxPageOrg > 1 ?
                          <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, padding: '15px 15px 0px', textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                            <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                              <IconButton title='Page Backward' onClick={() => this.handleOrgPageChange(-1)}>
                               <ChevronLeft fontSize='large'></ChevronLeft>
                              </IconButton>
                              {this.state.currentPageOrg + ' of ' + this.state.maxPageOrg}
                              <IconButton title='Page Forward' onClick={() => this.handleOrgPageChange(1)} >
                                <ChevronRight fontSize='large'></ChevronRight>
                              </IconButton>
                            </div>
                          </div>
                          :null
                          }
                        <div style={{ width: '850px'}}>
                          <Paper elevation={6}>
                            <UB04 currentPage={this.state.currentPageAdj} maxPage={this.state.maxPageAdj} isValidHandler={isFormValidUb04}></UB04>
                          </Paper>
                        </div>
                        </Paper>
                      </div>
                    </Col>
                    :            <Col sm={6}>
                    <div style={{display:'table', margin: 'auto'}}>
                      <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{'Original Claim'.toUpperCase()}</h5>
                      <Paper style={{ width: '850px'}} elevation={6} onClick={() => this.changeZindex('orgClaim')}>
                        {this.state.maxPageOrg > 1 ?
                        <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, padding: '15px 15px 0px', textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                          <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                            <IconButton title='Page Backward' onClick={() => this.handleOrgPageChange(-1)}>
                             <ChevronLeft fontSize='large'></ChevronLeft>
                            </IconButton>
                            {this.state.currentPageOrg + ' of ' + this.state.maxPageOrg}
                            <IconButton title='Page Forward' onClick={() => this.handleOrgPageChange(1)} >
                              <ChevronRight fontSize='large'></ChevronRight>
                            </IconButton>
                          </div>
                        </div>
                        :null
                        }
                        <CMSForm adjustedClaim={this.state.selectedPackage} currentPage={this.state.currentPageOrg} original={true} disabled={'all'} mode='locked' isValidHandler={isOrigFormValid}/>
                      </Paper>
                    </div>
                  </Col>
                    :null}
                    {/* editenabled = {this.state.editenabled}  We are not editing nor validating original claim */}
                    {this.state.showUB04 ? 
                       <Col sm={this.state.twoSided || this.state.showEOP || this.state.showMrmPdf || this.state.showHistoryPDF || this.state.showEOP ?6:12}>
                       <div style={{display:'table', margin: 'auto'}}>
                         <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{'Adjusted UB04 Form'.toUpperCase()}</h5>
                         <Paper style={{ width: '850px'}} elevation={6} onClick={() => this.changeZindex('orgClaim')}>
                         {this.state.maxPageAdj > 1 ?
                           <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, padding: '15px 15px 0px', textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                             <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                               <IconButton title='Page Backward' onClick={() => this.handleAdjPageChange(-1)}>
                                 <ChevronLeft fontSize='large'></ChevronLeft>
                               </IconButton>
                               {this.state.currentPageAdj + ' of ' + this.state.maxPageAdj}
                               <IconButton title='Page Forward' onClick={() => this.handleAdjPageChange(1)}>
                                 <ChevronRight fontSize='large'></ChevronRight>
                               </IconButton>
                           </div>
                         </div>
                         :null
                         }
                        <div style={{ width: '850px'}}>
                          <Paper elevation={6}>
                            <UB04 currentPage={this.state.currentPageAdj} maxPage={this.state.maxPageAdj} isValidHandler={isFormValidUb04}></UB04>
                          </Paper>
                        </div>
                       </Paper>
                       </div>
                     </Col>
                     :
                    <Col sm={this.state.twoSided || this.state.showEOP || this.state.showMrmPdf || this.state.showHistoryPDF || this.state.showEOP ?6:12}>
                      <div style={{display:'table', margin: 'auto'}}>
                        <h5 style={{textAlign:'center', marginTop:20, marginBottom: 20}}>{this.state.selectedPackage.claimInd}</h5>
                        <Paper style={{ width: '850px'}} elevation={6} onClick={() => this.changeZindex('orgClaim')}>
                        {this.state.maxPageAdj > 1 ?
                          <div style={{backgroundColor: 'white',position:'sticky',top:50,width:'100%', zIndex:999, padding: '15px 15px 0px', textAlign: 'center',display: 'flex',justifyContent: 'center'}}>
                            <div style={{border:'2px solid #e73f33', borderRadius:500, fontSize:20, position:'sticky',top:50,width:'25%'}} >
                              <IconButton title='Page Backward' onClick={() => this.handleAdjPageChange(-1)}>
                                <ChevronLeft fontSize='large'></ChevronLeft>
                              </IconButton>
                              {this.state.currentPageAdj + ' of ' + this.state.maxPageAdj}
                              <IconButton title='Page Forward' onClick={() => this.handleAdjPageChange(1)}>
                                <ChevronRight fontSize='large'></ChevronRight>
                              </IconButton>
                          </div>
                        </div>
                        :null
                        }
                        <CMSForm adjustedClaim={this.state.selectedPackage} original={false} currentPage={this.state.currentPageAdj} disabled={'partial'} editenabled = {this.state.editenabled} mode={this.state.cmsMode} isValidHandler={isFormValid} showErrorMessage={this.state.showErrorMessage} disabledAccept={this.disabledAccept} enableAccept={this.enableAccept} disabledAcceptButton={this.state.disabledAccept}/>
                      </Paper>
                      </div>
                    </Col>
                    }
                    </>
                }
                  </Row>
                </Container>

                : <Container fluid="xs" className="m-0 p-0" style={{width:'1880px', maxWidth:'1880px'}}>
                    <Row >
                      <Loading/> 
                    </Row> 
                  </Container>
                }
           
          </Col>
        </Row>
        <Row>
            <Col sm={5}></Col>
            <Col sm={4} className="justify-content-center" style={{paddingBottom:'60px', paddingTop: '100px'}}>
                &copy;2022 Optum, Inc. All rights reserved. 
            </Col>
            <Col sm={3}></Col>
        </Row>
        <Row className="m-0 p-0">
          <Col sm={12}>
              <NavBarBottom buttonsHandler={this.actionHandler} closed={this.state.closed} active={this.state.active} disabledAccept={this.state.disabledAccept} editenabled={this.state.editenabled} readOnly={this.state.readOnly}/>
          </Col>
        </Row>
        <Modal
          size="xl"
          show={this.state.showProvidersModal}
          onHide={() => this.setState({showProvidersModal:false})}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Your list of Providers
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid className='ag-theme-balham'>
              <Row style={{height:'500px'}}>
                <Col>
                  <PackageProviders2 providertins={this.state.providertins} active={this.state.active}/>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          show={this.state.showMedicalRecord}
          onHide={() => this.setState({showMedicalRecord:false})}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Medical Record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid className='ag-theme-balham'>
              <Row style={{height:'100%'}}>
                <Col>
                <h6>This will show the medical record for the selected claim {this.state.selectedPackage?this.state.selectedPackage.claim_id_new:null} with barcode {this.state.selectedPackage?this.state.selectedPackage.chartid:null}</h6>
                  {/* <PackageProviders2 providertins={this.state.providertins}/> */}
                  {/* <Document file='http://localhost:3050/MedicalRecord-Small.pdf'/> */}
                </Col>
              </Row>
              <Row style={{height:'500px'}}>
                <Col>
                  {/* <embed src="https://www.optum.com/content/dam/optum3/events/skins/logos/optum_2x.png" frameborder="0" width="100%" height="100%" type="application/pdf"></embed> */}
                  <embed src={process.env.PUBLIC_URL + '/MedicalRecord-Medium.pdf'} frameborder="0" width="100%" height="100%" type="application/pdf"></embed>
                  {/* `${process.env.PUBLIC_URL}/MedicalRecord-Medium.pdf` */}
                  {/* "http://www.africau.edu/images/default/sample.pdf" */}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          show={this.state.showAutoSign}
          onHide={() => this.setState({showAutoSign:false, autoSigned:false})}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Accept and Sign
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid className='ag-theme-balham'>
              <Row style={{height:'150px'}}>
                <Col>
                <p style={{color:'red'}}>{'31. Signature of Physician or Supplier Including Degrees or Credentials'.toUpperCase()} (I certify that the statements on the reverse apply to this bill and are made a part thereof.)</p>
                <p></p>
                  {/* <Form>
                     SIGNED <Form.Control as='input' size='lg' type='text' inline style={{fontFamily:'Brush Script MT'}} value='Geo Martinez'/> DATE <Form.Control inline as='input' size='lg' type='text' />
                  </Form> */}
                  <div>
                    {!this.state.autoSigned?
                    <>
                      <Row>
                        <Col xs={6} style={{color:'red'}}>SIGNED<input type='text' size='16' style={{borderColor:'red'}} disabled={true}/></Col>
                        <Col xs={6} style={{color:'red'}}>DATE<input type='text' size='10' style={{borderColor:'red'}} disabled={true}/></Col>
                      </Row>
                      </>
                    :
                    <>
                      <Row><Col xs={12}><h6 style={{textAlign:'center'}}>ELECTRONIC SIGNATURE</h6></Col></Row>
                      <Row>
                        <Col xs={2}>SIGNED</Col>
                        <Col xs={5}><div style={{ textTransform:'capitalize'}}>{this.state.selectedPackage.provider.firstname + ' ' + this.state.selectedPackage.provider.lastname}</div></Col>
                        <Col xs={1}>DATE</Col>
                        <Col xs={4}><div >{moment().format('M/D/YYYY hh:mm:ss')}</div></Col>
                      </Row>
                      </>
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
          {!this.state.autoSigned?
            <Button  className='rounded-pill border' onClick={this.autoSign} variant='warning'>AUTO SIGN</Button>
            :
            <Button variant='warning' className='rounded-pill border' onClick={this.finalAccept}>ACCEPT AND SIGN</Button>
          }
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={this.state.showRejectReason}
          onHide={() => this.setState({showRejectReason:false, showErrorMessage: true})}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Rejection Reason 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <Container fluid className='ag-theme-balham'>
              <Row style={{height:'180px'}}>
              {/* <input type='radio' id='rejectionReason' key={i+1} value={opt} /> */}
                <Col xs={12}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            {this.rejectionOptions.map((opt,i)=>{
                              if(i<=6){
                                return (<div key={'div1=' + (i+1).toString()}>
                                  <input type='radio' name='rejectionReason' key={'input1=' + (i+1).toString()} value={opt.toUpperCase()} onChange={this.setRejectionReason}/>
                                  <label key={'label1=' + (i+1).toString()} htmlFor={(i+1).toString()}>{opt.toUpperCase()}</label>
                                </div>)
                              }
                              return '' 
                            })}
                          </td>
                          <td>
                            {this.rejectionOptions.map((opt,i)=>{
                              if(i>6){
                                return (<div key={'div2=' + (i+1).toString()}>
                                  <input type='radio' name='rejectionReason' key={'input2=' +(i+1).toString()} value={opt.toUpperCase()} onChange={this.setRejectionReason}/>
                                  <label key={'label2=' + (i+1).toString()} htmlFor={(i+1).toString()}>{opt.toUpperCase()}</label>
                                </div>)
                              } 
                              return '' 
                            })}
                            <input type='radio' name='rejectionReason' key={(this.rejectionOptions.length+2).toString()} value={'other'.toUpperCase()} onChange={this.setRejectionReason}/>
                            <label htmlFor={(this.rejectionOptions.length+2).toString()}>OTHER</label>&emsp;<input type='text' size='40' name='otherRejectReason' onChange={this.setOtherRejectionReason} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    Caution - please double check your selection before the claim is rejected.  Once REJECT is selected, the claim will be final and changes will not be able to be made unless you contact your Optum Outreach Representative.
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
          <Button variant='warning' className='rounded-pill border' name='rejectButton' onClick={this.rejectHandler} disabled={this.state.rejectionReason?false:true} >REJECT</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(DashCMS2);
