import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { XCircle, List } from "react-bootstrap-icons";
import Loading from "./Loading";
import _ from "underscore";
import "./Pdf.css";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { createTheme,ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: 12,
    },
  },
});

const Pdf = (props) => {
  const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
  const handleDocumentLoad = () => setDocumentLoaded(true);
  const [matches, setMatches] = React.useState([]);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
          Rotate,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div style={{ padding: "0px 2px" }} onClick={props.name === "mrm" ? props.rotateClickStats : null}>
              <Rotate />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: "0px 2px", width: "4rem" }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>
            <div
              style={{ padding: "0px 2px", marginLeft: "auto", marginRight: 5 }}
            >
              <XCircle
                size={17}
                style={{
                  backgroundColor: "trasnparent",
                  border: "none",
                  borderRadius: ".25rem",
                  color: "#6d6f70",
                  height: "2rem",
                  padding: 0,
                }}
                cursor="pointer"
                title="Close PDF"
                onClick={props.closePDF}
              ></XCircle>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const pageNavigationInstance = pageNavigationPlugin();
  const uniqueLabels = props.uniqueLabels;
  // console.log(uniqueLabels);
  const searchPluginInstance = searchPlugin({
    keyword: uniqueLabels,
  });
  const { highlight } = searchPluginInstance;
  const { jumpToPage } = pageNavigationInstance;

  React.useEffect(() => {
    if (props.pageIndexs === null && props.name === "mrm") {
      if (isDocumentLoaded) {
        highlight(props.uniqueLabels).then((matches) => {
          // console.log(matches)
          if(matches.length < 1) {
            props.showMrmDosAlert()
          }
          let arr = [];
          for (let x = 0; x < matches.length; x++) {
            let pages = []
            for (let y = 0; y < matches.length; y++) {
              if(matches[x].keyword === matches[y].keyword) {
                pages.push(matches[y].pageIndex)
              }
            }
            let match = matches[x].keyword;
            match = match.toString();
            match = match.replace(/\\/g, "");
            match = match.replace("/gi", "");
            match = match.substr(1);
            console.log(matches);
            console.log(matches[x].keyword);
            console.log(match);
            arr.push({
              search_term_label: `Patient_DOS=${match}`,
              pages: pages,
            });

          }
          arr = arr.filter((value, index) => {
            let _value = JSON.stringify(value);
            return (
              index ===
              arr.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          });

          setMatches(arr);
        });
      }
    } else {
      if (props.pageIndexs !== null && props.name === "mrm" && props.mrmLables.filter(e => e.search_term_label.substr(0, e.search_term_label.indexOf("=")).includes("DOS")).length === 0) { 
        props.showMrmDosAlert()
      }
    }
  }, [isDocumentLoaded]);

  const searchKeywords = (page) => {
    jumpToPage(page);
  };

  const beautifyLabel = (labels) => {
    let val = [];
    let labelName = "";
    let labelValue = "";
    let labelArr = [];

    val = labels.includes("=") ? labels.split("=") : labels.split("-");
    labelName = val[0];

    labelValue = val[1];
    labelName = labelName.replace(/_/gi, " ");
    labelArr = labelName.split(" ");

    for (let i = 0; i < labelArr.length; i++) {
      labelArr[i] = labelArr[i].toLowerCase();
      labelArr[i] = labelArr[i][0].toUpperCase() + labelArr[i].substr(1);
    }

    labelName = labelArr.join(" ");

    labelValue = labelValue.toLowerCase();
    labelValue = labelValue.charAt(0).toUpperCase() + labelValue.slice(1);

    return labelName.toUpperCase() + " = " + labelValue.toUpperCase();
  };

  const customSidebarTabs = (defaultTabs) => {
    if (props.name === "mrm") {
      return [
        // Custom thumbnail tab
        {
          content: (
            <div
              style={{
                alignItems: "center",
                width: "100%",
              }}
            >
              {props.pageIndexs !== null ? (
                props.mrmLables.filter(e => e.search_term_label.substr(0, e.search_term_label.indexOf("=")).includes("DOS")).length > 0 ?
                _.map(props.mrmLables, (p, i) =>
                  p.search_term_label.includes("DOS") ? (
                    <ThemeProvider theme={theme}>
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        sx={{
                          flexGrow: 1,
                          maxWidth: 400,
                          overflowY: "auto",
                        }}
                      >
                        <TreeItem
                          nodeId={`${p.search_term_label}`}
                          label={beautifyLabel(p.search_term_label)}
                          style={{ textAlign: "left", fontSize: 10 }}
                        >
                          {_.map(p.pages, (x, i) => (
                            <TreeItem
                              nodeId={`${p.search_term_label}-${i}`}
                              value={x - 1}
                              onClick={() => searchKeywords(x - 1)}
                              style={{ textAlign: "left", fontSize: 10 }}
                              label={`Page ${x}`}
                            />
                          ))}
                        </TreeItem>
                      </TreeView>
                    </ThemeProvider>
                  ) : null
                ) :   <div>
                        <div
                          style={{
                            padding: "0px 2px",
                            margin: "auto",
                          }}
                        >
                          <p>Could not auto-match Date of Service. Please review the Medical Record manually to locate the Date of Service.</p>
                        </div>
                      </div>
              ) : matches.length > 0 ? (
                _.map(matches, (p, i) => (
                  <ThemeProvider theme={theme}>
                    <TreeView
                      aria-label="file system navigator"
                      defaultCollapseIcon={<ExpandMore />}
                      defaultExpandIcon={<ChevronRight />}
                      sx={{
                        flexGrow: 1,
                        maxWidth: 400,
                        overflowY: "auto",
                      }}
                    >
                      <TreeItem
                        nodeId={`${p.search_term_label}`}
                        label={beautifyLabel(p.search_term_label)}
                        style={{ textAlign: "left", fontSize: 10 }}
                      >
                        {_.map(p.pages, (x, i) => (
                          <TreeItem
                            nodeId={`${p.search_term_label}-${i}`}
                            value={x - 1}
                            onClick={() => searchKeywords(x)}
                            style={{ textAlign: "left", fontSize: 10 }}
                            label={`Page ${x + 1}`}
                          />
                        ))}
                      </TreeItem>
                    </TreeView>
                  </ThemeProvider>
                ))
              ) : (
                <div>
                  <div
                    style={{
                      padding: "0px 2px",
                      margin: "auto",
                    }}
                  >
                    <p>Could not auto-match Date of Service. Please review the Medical Record manually to locate the Date of Service.</p>
                  </div>
                </div>
              )}
            </div>
          ),
          icon: (
            <List
              size={17}
              style={{
                backgroundColor: "trasnparent",
                border: "none",
                borderRadius: ".25rem",
                color: "#6d6f70",
                height: "2rem",
                padding: 0,
              }}
              cursor="pointer"
              title="Medical Record Term Index"
            ></List>
          ),
          title: "Search Terms",
        },
        // Bookmarks tab
        // defaultTabs[1],
      ];
    } else {
      return [];
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => customSidebarTabs(defaultTabs),
    renderToolbar,
  });

  const getFirstPage = (mrmLables) => {
    let pages = []
    for(let x = 0; x < mrmLables.length; x++) {
      let label = mrmLables[x].search_term_label
          label = label.substr(0,label.indexOf("="))
      let mrmPages = mrmLables[x].pages
        if(label.includes("DOS")) {
          for(let y = 0; y < mrmPages.length; y++) {
            let val = mrmPages[y];
            if(!pages.includes(val)) {
            pages.push(val)
            }
          }
        }   
    }

   let firstPage = Math.min(...pages)
   return firstPage - 1
  }

  const renderError = (error) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message = "Cannot load the document";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }

    if (props.backupUrl && props.backupUrl !== props.url) {
      return (
        <Viewer
          fileUrl={props.backupUrl}
          // httpHeaders={{
          //   'Authorization': `Bearer ${localStorage.getItem("token")}`,
          // }}
          plugins={[
            defaultLayoutPluginInstance,
            searchPluginInstance,
            pageNavigationInstance,
          ]}
          renderLoader={(percent) => <Loading />}
          renderError={renderError}
          transformGetDocumentParams={(options) =>
            Object.assign({}, options, {
              disableStream: true,
              disableAutoFetch: true,
              rangeChunkSize: 1000000,
            })
          }
        />
      );
    } else {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#e53e3e",
              borderRadius: "0.25rem",
              color: "#ffffff",
              padding: "0.5rem",
            }}
          >
            {message}
          </div>
        </div>
      );
    }
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
      <div
        style={{
          height: 1210,
          width: "850px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Viewer
          fileUrl={props.url}
          // httpHeaders={{
          //   'Authorization': `Bearer ${localStorage.getItem("token")}`,
          // }}
          plugins={[
            defaultLayoutPluginInstance,
            searchPluginInstance,
            pageNavigationInstance,
          ]}
          renderLoader={(percent) => <Loading />}
          renderError={renderError}
          initialPage={
            props.name === "mrm" &&
            props.pageIndexs !== null &&
            props.mrmLables.filter(e => e.search_term_label.substr(0, e.search_term_label.indexOf("=")).includes("DOS")).length > 0
            ? getFirstPage(props.mrmLables) : null
          }
          onDocumentLoad={handleDocumentLoad}
          transformGetDocumentParams={(options) =>
            Object.assign({}, options, {
              // disableRange: false,
              disableStream: true,
              disableAutoFetch: true,
              rangeChunkSize: 1000000,
            })
          }
        />
      </div>
    </Worker>
  );
};

export default Pdf;
