import React, { Component } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import _ from "underscore";

class DashboardBar extends Component {
  state = {
    //providertins:['232837433','463318810','251876056','232176338','481088982','480125463'], //coming from auth piece
    providertins: this.props.providertins
      ? this.props.providertins
      : localStorage.getItem("tin").split(","),
    showPackages:localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:true,
    showProviders:localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
    showPayments: localStorage.getItem('showPayments')&&localStorage.getItem('showPayments')==='true'?true:false,
    active:true,
    packageVer:this.props.packageVer
  };

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active!==this.state.active || prevProps.packageVer !== this.props.packageVer) {
      this.callApi()
    }
    if(prevProps.showVer !== this.props.showVer) {
      this.showGrids()
    }
    if(prevProps.hideVer !== this.props.hideVer) {
      this.hideGrids()
    }
  }

  callApi =()=>{
    if (this.state.providertins) {
      let initial = {
        totalPackages: 0,
        newDayClaims: 0,
        newPackages: 0,
        adjustedPackages: 0,
        pendedPackages: 0,
        newTodo: 0,
        newAccepted: 0,
        newRejected: 0,
        adjustedTodo: 0,
        adjustedAccepted: 0,
        adjustedRejected: 0,
        aged:0,
        days7younger: 0,
        days7older: 0,
        days14older: 0,
        tinsCount: 0,
      };
      fetch(
        localStorage.getItem("apiUrl") +
          `/getPackageBreakdown?tax_id=${this.state.providertins.join(",")}&active=${this.state.active}`,
      )
        .then((res) => res.json())
        .then((resj) => {
          if (resj.rows && resj.rows.length > 0) {
            _.each(resj.rows, (row, i) => {
              initial.totalPackages += row.totalPackages;
              initial.newDayClaims += row.newDayClaims;
              initial.newPackages += row.newPackages;
              initial.pendedPackages += row.pendedPackages;
              initial.aged += row.aged;
              initial.days7younger += row.days7younger;
              initial.days7older += row.days7older;
              initial.days14older += row.days14older;
              initial.adjustedPackages += row.adjustedPackages;
              initial.newTodo += row.newTodo;
              initial.newAccepted += row.newAccepted;
              initial.newRejected += row.newRejected;
              initial.adjustedTodo += row.adjustedTodo;
              initial.adjustedAccepted += row.adjustedAccepted;
              initial.adjustedRejected += row.adjustedRejected;
            });
            initial.tinsCount = resj.rows.length;
            this.setState(initial);
            this.props.countCheck(initial)
          } 
          // else {
          //   //redirect to logoff user with the oops message
          //   console.log('got to else no packages')
          //   this.setState({showOoops:true})
          // }
        })
        .catch(console.log);
    }
  }

  handleLogoff = () => {
    // post to 6cLogging here for the logoff event
    let userid = localStorage.getItem("u");
    let forPosting = {
      product: "DMS ReportGrid",
      class: "SECURITY_AUDIT",
      sourceUserMSID: userid,
      message: "TerminateUserSession:SUCCESS",
      severity: "INFO",
    };
    console.log(`starting the post for ${JSON.stringify(forPosting)}`);
    console.log(localStorage.getItem("authUrl"));
    fetch(`${localStorage.getItem("authUrl")}/dms/post6cLog`, {
      method: "post",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(forPosting),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        localStorage.clear();
        // window.close()
        this.props.history.push({pathname:"/"});
        window.location.reload()
      })
      .catch(console.log);
  };

  clickHandler = (e) => {
    console.log(e.target.name);
    this.props.handlers(e.target.name);
  };

  showGrids = () => {
    this.setState({
      showPackages:localStorage.getItem('showPackages')&&localStorage.getItem('showPackages')==='true'?true:false,
      showProviders:localStorage.getItem('showProviders')&&localStorage.getItem('showProviders')==='true'?true:false,
      showPayments:localStorage.getItem('showPayments')&&localStorage.getItem('showPayments')==='true'?true:false,
    })
  }

  hideGrids = () => {
    this.setState({showPackages: false, showProviders: false})
  }

  displayHandler = (e) => {
    console.log(e.target);
    console.log(e.target.innerHTML + e.target.textContent);
    // e.target.textContent = `Un${e.target.textContent}`;
    switch (e.target.name) {
        case 'packagesDisplay':
          this.props.displayHandlers(e.target.name)
          this.setState({showPackages:!this.state.showPackages})
          break;
        case 'providersDisplay':
          this.props.displayHandlers(e.target.name)
          this.setState({showProviders:!this.state.showProviders})
          break;
        case 'paymentsDisplay':
          this.props.displayHandlers(e.target.name)
          this.setState({showPayments:!this.state.showPayments})
          break;
        case 'activeInactive':
          this.props.displayHandlers(e.target.name)
          this.setState({active:!this.state.active})
          break;
    
        default:
            break;
    }
  };
  // clickNewOpen=()=>{
  //     console.log('newOpen')
  //     this.props.handlers('newOpen')
  // }
  // clickNewAccepted=()=>{
  //     console.log('NewAccepted')
  //     this.props.handlers('NewAccepted')
  // }
  // clickNewRejected=()=>{
  //     console.log('NewRejected')
  //     this.props.handlers('NewRejected')
  // }

  render() {
    return (
      <Container className="m-0 p-0 ag-theme-balham" fluid>
        <Row>
          <Col sm={12}>
            <Table
              className="m-0 p-0 text-center ag-theme-balham"
              bordered
              size="sm"
            >
            <tbody >
              {/* <thead
                className="m-0 p-0 text-center ag-theme-balham"
                style={{ backgroundColor: 'white' }}
              > */}
                <tr>
                  <td style={{width: '20%'}}>
                    <div>
                      <Button 
                        className='rounded-pill border'
                        size="md"
                        variant="warning"
                        block
                        tooltip={this.state.providertins}
                        style={{ 
                          // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor:'#ff612b',
                        color:'white'
                         }}
                        name="showProvidersModal"
                        onClick={this.clickHandler}
                        title='List of Physicians with claims to review'
                      >
                        {this.state.providertins.length > 1
                          ? "My Network TIN"
                          : "My TIN"}{" "}
                        ({this.state.tinsCount})
                      </Button>
                    </div>
                  </td>
                  {localStorage.getItem('active')==='true'?
                  <td style={{width: '20%'}}>
                    <Button
                      size="md"
                      variant="warning"
                      className='border'
                      block
                      name='aged'
                      onClick={this.clickHandler}
                      title='Claims with no action taken'
                      style={{ 
                        borderRadius:'16px',backgroundColor:'#ff612b', color:'white' }}
                    >
                      Aged Claims (
                      {this.state.aged})
                    </Button>
                  </td>
                  :null}
                  {/* <th><Button size='sm' variant="success" block  style={{boxShadow:'2px 2px 4px silver'}}>My Reports</Button></th> */}
                  {/* <th>
                    <Button
                      size="sm"
                      className='border'
                      variant="success"
                      block
                      // style={{ boxShadow: "2px 2px 4px silver" }}
                      name="newDay"
                      onClick={this.clickHandler}
                    >
                      New Day Claims({this.state.newDayClaims})
                    </Button>
                  </th> */}
                  <td>
                    <Button
                      size="md"
                      className='border'
                      variant="warning"
                      block
                      style={{ 
                        borderRadius:'16px',backgroundColor:'#ff612b', color:'white' }}
                      name="adjusted"
                      onClick={this.clickHandler}
                      title='All Pseudo Claims'
                    >
                      Adjusted Claims ({this.state.adjustedPackages})
                    </Button>
                  </td>
                  {/* <th>
                    <Button
                      size="sm"
                      className='border'
                      variant="warning"
                      block
                      style={{ 
                        borderRadius:'16px' }}
                      name="all"
                      onClick={this.clickHandler}
                      title='All Claims'
                    >
                      All ({this.state.totalPackages})
                    </Button>
                  </th> */}
                  {/* <td>
                    <Button
                      size="md"
                      className='border'
                      variant="warning"
                      block
                      style={{ 
                        borderRadius:'16px',backgroundColor:'#ff612b', color:'white' }}
                      title='Supporting documents'
                    >
                      Supporting Documents
                    </Button>
                  </td> */}
                  <td>
                    <Button
                      size="md"
                      className='border'
                      variant="warning"
                      block
                      style={{ 
                        borderRadius:'16px',backgroundColor:'#ff612b', color:'white' }}
                      title='Your preferred view of Packages or Providers with Claims'
                    >
                      Preferences
                    </Button>
                  </td>
                </tr>
              {/* </thead> */}
              {/* <tbody> */}
                <tr>
                  <td>
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // boxShadow: "2px 2px 4px silver",
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name="new"
                      onClick={this.clickHandler}
                      title='Unopened Claims'
                    >
                      New ({this.state.newPackages})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // boxShadow: "2px 2px 4px silver",
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name="pended"
                      onClick={this.clickHandler}
                      title='Opened Claims with no action taken'
                    >
                      Pended ({this.state.pendedPackages})
                    </Button>&emsp;
                    
                    
                  </td>
                  {localStorage.getItem('active')==='true'?
                  <>
                  <td>
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name='days7older'
                      title='Claims between 7 up to 14 days'
                      onClick={this.clickHandler}
                    >
                      7 days ({this.state.days7older})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name='days14older'
                      onClick={this.clickHandler}
                      title='Claims older than 14 days'
                    >
                      14 days ({this.state.days14older})
                    </Button>
                  </td>
                  </>
                  :null}
                  {/* <td>
                                        <Button size='sm' variant="warning" style={{boxShadow:'2px 2px 4px silver', fontWeight:'bold'}}>New</Button>&nbsp;
                                        <Button size='sm' variant="warning" style={{boxShadow:'2px 2px 4px silver', fontWeight:'bold'}}>Archive</Button>
                                    </td> */}
                  {/* <td>
                    <Button
                      size="sm"
                      variant="warning"
                      className='border'
                      style={{
                        // boxShadow: "2px 2px 4px silver",
                        // fontWeight: "bold",
                      }}
                      name="newOpen"
                      onClick={this.clickHandler}
                    >
                      Open({this.state.newTodo})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="warning"
                      style={{
                        // boxShadow: "2px 2px 4px silver",
                        // fontWeight: "bold",
                      }}
                      name="newAccepted"
                      onClick={this.clickHandler}
                    >
                      Accepted({this.state.newAccepted})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="warning"
                      style={{
                        // boxShadow: "2px 2px 4px silver",
                        // fontWeight: "bold",
                      }}
                      name="newRejected"
                      onClick={this.clickHandler}
                    >
                      Rejected({this.state.newRejected})
                    </Button>
                  </td> */}
                  <td>
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name="adjustedOpen"
                      onClick={this.clickHandler}
                      title='Open Adjusted Claims'
                    >
                      Open ({this.state.adjustedTodo})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name="adjustedAccepted"
                      onClick={this.clickHandler}
                      title='Accepted Adjusted Claims'
                    >
                      Accepted ({this.state.adjustedAccepted})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name="adjustedRejected"
                      onClick={this.clickHandler}
                      title='Rejected Adjusted Claims'
                    >
                      Rejected ({this.state.adjustedRejected})
                    </Button>
                  </td>
                  {/* <td>
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px'
                      }}
                      name="allOpen"
                      onClick={this.clickHandler}
                      title='Claims Currently Open'
                    >
                      Open ({this.state.newTodo + this.state.adjustedTodo})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px'
                      }}
                      name="allAccepted"
                      title='All Accepted Claims'
                      onClick={this.clickHandler}
                    >
                      Accepted{" "}
                      ({this.state.adjustedAccepted + this.state.newAccepted})
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px'
                      }}
                      name="allRejected"
                      onClick={this.clickHandler}
                      title='All Rejected Claims'
                    >
                      Rejected{" "}
                      ({this.state.adjustedRejected + this.state.newRejected})
                    </Button>
                  </td> */}
                  {/* <td>
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px'
                      }}
                      name="praDisplay"
                      // onClick={this.displayHandler}
                      title='Hide/Show Provider Remitance Advice'
                    >
                      PRA
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px'
                      }}
                      title='Hide/Show Explanation of Payment'
                      name="eopDisplay"
                      // onClick={this.displayHandler}
                    >
                      EOP
                    </Button>
                  </td> */}
                  <td>
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      name="packagesDisplay"
                      onClick={this.displayHandler}
                      title='Hide/Show Packages grid'
                    >
                      {this.state.showPackages?'Hide':'Show'} Packages
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      title='Hide/Show Providers grid'
                      name="providersDisplay"
                      onClick={this.displayHandler}
                    >
                      {this.state.showProviders?'Hide':'Show'} Providers
                    </Button>
                    &nbsp;
                    {JSON.parse(localStorage.getItem('userPayEligible'))?
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px'
                      }}
                      title='Hide/Show Payments grid'
                      name="paymentsDisplay"
                      onClick={this.displayHandler}
                    >
                      {this.state.showPayments?'Hide':'Show'} Payments
                    </Button>
                    :null}
                    &nbsp;
                    <Button
                      size="sm"
                      className='border'
                      variant="secondary"
                      style={{
                        // fontWeight: "bold",
                        borderRadius:'16px',
                        backgroundColor: "#6d6f70"
                      }}
                      title='Show Active or Closed Project Packages'
                      name="activeInactive"
                      onClick={this.displayHandler}
                    >
                      {this.state.active?'Closed':'Active'}&nbsp;Projects
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* <Modal
          size="lg"
          show={this.state.showOoops}
          onHide={() => this.handleLogoff()}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Ooops!<br/>You do not have any packages to review.<br/>When packages need to be reviewed, you will receive an email notification.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
          </Modal.Body>
        </Modal> */}
      </Container>
    );
  }
}

export default withRouter(DashboardBar);
