import React, { Component } from 'react';
import _ from 'lodash'
import {formatDateTime} from '../utils/layoutUtils'
import {Button} from 'react-bootstrap';


class DescriptionAlert extends Component {
    state = {
        alerts: []
    }

    componentDidMount() {
        this.callAPIRead()
    }

    callAPIRead = () =>{
        fetch(localStorage.getItem('apiUrl')+`/getAlerts`)
        .then(res=>res.json())
        .then(res=>{
            console.log(res)
            let forReturn=[...res.rows]
            forReturn = _.sortBy(forReturn,'dateTime').reverse()
            console.log(forReturn)
            this.setState({alerts:forReturn})
        })
        .catch( error => {
            console.log( error );
        });
    }

  render() {
  return (
  <div class="alert alert-light" style={{position: 'fixed', top: 0, left:0, bottom: 0, right: 0, margin: 'auto', zIndex: 3, maxHeight:350, width:1000, border: 'solid 1px #ff612b'}}  role="alert">
  <div style={{position: 'fixed', width:970}}>
      <h3 class="alert-heading" style={{color: '#ff612b', float: 'left'}}>Broadcast Alerts</h3>
      <Button variant="danger" size="sm" style={{float:'right'}} onClick={this.props.closeAlerts}>Cancel</Button>
  </div>
  <div style={{marginTop: 50, overflow: 'auto', height: 250}}>
  {this.state.alerts.map((data) => (
      <>
  <h6 style={{color: '#ff612b'}}>{formatDateTime(data.dateTime)} </h6>
  <p>{data.message}</p>
  <hr></hr>
  </>
  ))}
  </div>

 </div>
  );
}
}

export default DescriptionAlert
