import React, { Component } from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
//import { Container, Button } from "react-bootstrap";
//import {grouper} from '../utils/layoutUtils'
import { formatPhoneNumber } from "../utils/layoutUtils";

/**
 * @class PackageProviders
 * @descr Display providers by group ID
 * @version 1.0
 * @param
 * @returns provider list based on groupid
 */

class PackageProviders2 extends Component {
  state = {
    providers: [],
    providertins: this.props.providertins, //this will be provided by props to this component tin
    columnDefs: [
      {
        headerName: "Provider Information",
        children: [
          {
            headerName: "Provider ID",
            field: "provider_id",
            headerTooltip: "Provider ID",
            tooltipField: "provider_id",
            sortable: true,
            resizable: true,
            filter: true,
            width: 155,
          },
          {
            headerName: "Client",
            field: "clientid",
            headerTooltip: "Client",
            tooltipField: "clientid",
            sortable: true,
            resizable: true,
            filter: true,
            width: 80,
          },
          {
            headerName: "TIN",
            field: "tax_id",
            headerTooltip: "Provider TIN",
            tooltipField: "tax_id",
            sortable: true,
            resizable: true,
            filter: true,
            width: 95,
          },
          // {
          //   headerName: "NPI",
          //   field: "provider.npi",
          //   headerTooltip: "Provider NPI",
          //   tooltipField: "provider.npi",
          //   sortable: true,
          //   resizable: true,
          //   filter: true,
          //   width: 95,
          // },
          {
            headerName: "Last Name",
            field: "provider.lastname",
            headerTooltip: "Provider Last Name",
            tooltipField: "provider.lastname",
            sortable: true,
            resizable: true,
            filter: true,
            width: 125,
          },
          {
            headerName: "First Name",
            field: "provider.firstname",
            headerTooltip: "Provider First Name",
            tooltipField: "provider.firstname",
            sortable: true,
            resizable: true,
            filter: true,
            width: 110,
          },
          {
            headerName: "Phone",
            field: "provider.phone",
            headerTooltip: "Provider Phone",
            tooltipField: "provider.phone",
            sortable: true,
            resizable: true,
            filter: true,
            width: 115,
            valueFormatter: (params) => formatPhoneNumber(params.value),
          },
          {
            headerName: "Email",
            field: "provider.email",
            headerTooltip: "Provider Email",
            tooltipField: "provider.email",
            sortable: true,
            resizable: true,
            filter: true,
            width: 120,
          },
        ],
      },
      {
        headerName:'Group Information',
        children:[
          {
            headerName: "ID",
            field: "provider.groupid",
            headerTooltip: "Provider Group ID",
            tooltipField: "provider.groupid",
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { excelMode: "mac", newRowsAction: "keep" },
            width: 92,
          },
          {
            headerName: "Name",
            field: "provider.groupname",
            headerTooltip: "Provider Group Name",
            tooltipField: "provider.groupname",
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { excelMode: "mac", newRowsAction: "keep" },
            width: 255,
          },
        ]
      },
      {
        headerName: "Claim",
        children: [
          {
            headerName: "Count",
            field: "totalClaims",
            headerTooltip: "Count",
            sortable: true,
            resizable: true,
            filter: true,
            width: 80,
          },
        ],
      },
      // {
      //   headerName: "Alt Contact 1",
      //   children: [
      //     {
      //       headerName: "Last Name",
      //       field: "provider.altContact1.lastname",
      //       headerTooltip: "Alt Contact Last Name",
      //       tooltipField: "provider.altContact1.lastname",
      //       sortable: true,
      //       resizable: true,
      //       filter: true,
      //       filterParams: { excelMode: "mac", newRowsAction: "keep" },
      //       width: 120,
      //     },
      //     {
      //       headerName: "First Name",
      //       field: "provider.altContact1.firstname",
      //       headerTooltip: "Alt Contact First Name",
      //       tooltipField: "provider.altContact1.firstname",
      //       sortable: true,
      //       resizable: true,
      //       filter: true,
      //       filterParams: { excelMode: "mac", newRowsAction: "keep" },
      //       width: 110,
      //     },
      //     {
      //       headerName: "Phone",
      //       field: "provider.altContact1.phone",
      //       headerTooltip: "Alt Contact Phone",
      //       tooltipField: "provider.altContact1.phone",
      //       sortable: true,
      //       resizable: true,
      //       filter: true,
      //       filterParams: { excelMode: "mac", newRowsAction: "keep" },
      //       width: 115,
      //       valueFormatter: (params) => formatPhoneNumber(params.value),
      //     },
      //   ],
      // },
      // {
      //   headerName: "Alt Contact 2",
      //   children: [
      //     {
      //       headerName: "Last Name",
      //       field: "provider.altContact2.lastname",
      //       headerTooltip: "Alt Contact 2 Last Name",
      //       tooltipField: "provider.altContact2.lastname",
      //       sortable: true,
      //       resizable: true,
      //       filter: true,
      //       filterParams: { excelMode: "mac", newRowsAction: "keep" },
      //       width: 120,
      //     },
      //     {
      //       headerName: "First Name",
      //       field: "provider.altContact2.firstname",
      //       headerTooltip: "Alt Contact 2 First Name",
      //       tooltipField: "provider.altContact2.firstname",
      //       sortable: true,
      //       resizable: true,
      //       filter: true,
      //       filterParams: { excelMode: "mac", newRowsAction: "keep" },
      //       width: 110,
      //     },
      //     {
      //       headerName: "Phone",
      //       field: "provider.altContact2.phone",
      //       headerTooltip: "Alt Contact 2 Phone",
      //       tooltipField: "provider.altContact2.phone",
      //       sortable: true,
      //       resizable: true,
      //       filter: true,
      //       filterParams: { excelMode: "mac", newRowsAction: "keep" },
      //       width: 115,
      //       valueFormatter: (params) => formatPhoneNumber(params.value),
      //       pinnedRowValueFormatter: (params) => {
      //         return "Totals:";
      //       },
      //     },
      //   ],
      // },
    ],
    rowData: [],
    error: false,
        defaultColDef:{ suppressHeaderFilterButton: true, type: 'disableTreeList' },
        columnTypes:{
            disableTreeList: {
              filterParams: { treeList: false },
            },
          }
  };
  componentDidMount() {
    // console.log( this.props );
    fetch(
      localStorage.getItem("apiUrl") +
        "/getPackageProviders?providertins=" +
        this.state.providertins.join(",") + "&active=" + this.props.active
    )
      .then((res) => res.json())
      .then((resj) => {
        let forReturn = [...resj.rows];
        this.setState({ rowData: forReturn });
      })
      // .then(this.selectFirst())
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.providertins !== this.props.providertins ||
      prevProps.providerVer !== this.props.providerVer || prevProps.active !== this.props.active
    ) {
      fetch(
        localStorage.getItem("apiUrl") +
          "/getPackageProviders?providertins=" +
          this.props.providertins.join(",") + "&active=" + this.props.active
      )
        .then((res) => res.json())
        .then((res) => {
          let forReturn = [...res.rows];
          this.setState({ rowData: forReturn });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  clearFilter = () => {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
    }
  };

  changeProvider = (params) => {
    let prov = params.api.getSelectedNodes()[0];
    console.log(prov);
    this.props.changeHandler(prov.data);
  };

  // getPinnedBottomRows=(params)=> {
  //     let bRow = {totalClaims: 0 }
  //     bRow['provider']={} // create the provider object
  //     bRow['provider']['altContact2']={} // create the altContact2 object
  //     bRow['provider']['altContact2']['firstname']='Totals:'
  //     bRow['provider']['altContact2']['phone']='Total:'
  //     let bottomRowPinnedRows=[bRow]
  //     console.log(bottomRowPinnedRows)
  //     console.log(this.state.rowData)
  //     let rows=0

  //     params.api.forEachNodeAfterFilter((node, nodeInd) => {
  //         bottomRowPinnedRows.forEach(pinnedRow => {
  //             rows++
  //             if (rows === 1) {
  //             //    console.log(node.data.hcc_count)
  //                 pinnedRow.totalClaims = node.data.totalClaims
  //                 //pinnedRow.aC2firstname
  //                 //console.log(pinnedRow)
  //                 //pinnedRow["{provider.altContact2.firstname}"]='Totals:'
  //             }

  //             //switch (pinnedRow.firstname) {
  //             //    case 'Totals:':
  //                     if (rows === 1) {
  //                         return
  //                     }
  //                     pinnedRow.totalClaims += node.data.totalClaims
  //                     //pinnedRow.provider.altContact2.firstname='Totals:'
  //                     //console.log(pinnedRow)
  //             //        return
  //             //    default:
  //             //}
  //         })
  //     })
  //     //console.log(bottomRowPinnedRows)
  //     params.api.setPinnedBottomRowData(bottomRowPinnedRows)
  // }

  // onFirstDataRendered=(params)=>{
  //     this.getPinnedBottomRows(params)
  // }

  // selectFirst=()=>{
  //     this.gridApi.forEachNode((node)=>{
  //         console.log('got into forEachNode')
  //         if (node.rowIndex===3) {
  //             node.setSelected(true)
  //         }
  //     })
  // }

  onGridReady = (params) => {
    console.log("got into onGridReady");

    this.gridApi = params.api;
    console.log("getting into for each");
    // console.log(this.gridApi)
    this.gridApi.forEachNode((node) => {
      console.log(node.rowIndex);
      node.setSelected(node.rowIndex === 0);
    });
    // this.gridApi.selectAll()
  };

  render() {
    return (
      <AgGridReact
        onGridReady={this.onGridReady}
        rowSelection={{checkboxes:false, headerCheckbox:false, "copySelectedRows":false}}
        // rowSelection="single"
        // onRowSelected = {this.rowSelectedHandler}
        // onSelectionChanged={this.changeProvider}
        pagination={true}
        paginationAutoPageSize={true}
        autoSize={true}
        columnDefs={this.state.columnDefs}
        rowData={this.state.rowData}
        groupHeaderHeight={20}
        headerHeight={40}
        rowHeight={20}
        // pinnedBottomRowData={null}
        // onFirstDataRendered={this.onFirstDataRendered}
        // onFilterChanged = {this.filterChangedHandler}
        enableCellTextSelection={true}
      //  suppressCopyRowsToClipboard={true}
        defaultColDef={this.state.defaultColDef}
        columnTypes={this.state.columnTypes}

        //statusBar={this.state.statusBar}
      ></AgGridReact>
    );
  }
}

export default PackageProviders2;
